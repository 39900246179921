import React, { useEffect, useState, useCallback } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash"; // Ensure lodash is installed or use a custom debounce function

const ScrollIndicator = ({ lastGenerationPosition, editorRef }) => {
  const [showIndicator, setShowIndicator] = useState(false);
  const [isAbove, setIsAbove] = useState(true);
  const [highlightType, setHighlightType] = useState(null);

  const checkPosition = useCallback(() => {
    if (!editorRef?.current || lastGenerationPosition === null) {
      console.log("Editor ref or last generation position is not set");
      setShowIndicator(false);
      return;
    }

    const scrollContainer = editorRef.current.querySelector(".ProseMirror");
    if (!scrollContainer) {
      console.log("ProseMirror container not found");
      return;
    }

    const highlightElements = Array.from(
      scrollContainer.querySelectorAll('[data-highlight-id][data-is-new="true"]')
    ).filter((el) => el.getAttribute("data-is-highlight") !== "true");

    console.log("Found highlight elements:", highlightElements.length);

    if (highlightElements.length > 0) {
      const lastHighlight = highlightElements[highlightElements.length - 1];
      const type = lastHighlight.getAttribute("data-highlight-type");
      setHighlightType(type);
    }

    const scrollTop = scrollContainer.scrollTop;
    const positionRelativeToView = lastGenerationPosition - scrollTop;
    const viewportHeight = window.innerHeight;
    const isInViewport =
      positionRelativeToView >= 0 && positionRelativeToView <= viewportHeight;
    const isTargetAbove = positionRelativeToView < 0;

    console.log("Position checks:", { positionRelativeToView, isInViewport });

    setShowIndicator(!isInViewport && highlightElements.length > 0);
    setIsAbove(isTargetAbove);
  }, [lastGenerationPosition, editorRef]);

  useEffect(() => {
    checkPosition();
    const debouncedResize = debounce(() => {
      checkPosition();
    }, 300);

    window.addEventListener("resize", debouncedResize);
    document.addEventListener("contentUpdate", checkPosition);

    return () => {
      window.removeEventListener("resize", debouncedResize);
      document.removeEventListener("contentUpdate", checkPosition);
    };
  }, [checkPosition]);

  const handleClick = () => {
    const scrollContainer = editorRef.current?.querySelector(".ProseMirror");
    if (!scrollContainer) {
      console.error("Scroll container not found");
      return;
    }
  
    // Find the target highlighted element
    const highlightElement = scrollContainer.querySelector(
      `[data-highlight-id][data-is-new="true"]`
    );
  
    if (highlightElement) {
      highlightElement.scrollIntoView({
        behavior: "smooth",
        block: "center", // Scroll the element to the center of the viewport
      });
      console.log("Scrolled to highlight element");
    } else {
      console.warn("No highlight element found, using fallback position");
      scrollContainer.scrollTo({
        top: lastGenerationPosition,
        behavior: "smooth",
      });
      console.log("Scrolled to:", lastGenerationPosition);
    }
  };
  

  const pingColorClasses = {
    question: "bg-blue-500",
    fallacy: "bg-rose-500",
    nugget: "bg-purple-500",
    note: "bg-green-500",
  };

  const borderColorClasses = {
    question: "border-blue-500",
    fallacy: "border-rose-500",
    nugget: "border-purple-500",
    note: "border-green-500",
  };

  const pingColor = pingColorClasses[highlightType] || "blue";

  const borderColor = borderColorClasses[highlightType] || "border-blue-500";

  if (!showIndicator) return null;

  return (
    <div
      className={`fixed left-4 md:left-8 ${
        isAbove ? "top-24" : "bottom-24"
      } z-[20] cursor-pointer group ${showIndicator ? "animate-bounce" : ""}`}
      onClick={handleClick}
    >
      <div className="relative flex items-center justify-center">
        <div
          className={`relative bg-white/70 dark:bg-gray-800/70 hover:bg-white/90 dark:hover:bg-gray-800/90 backdrop-blur-sm border ${borderColor} dark:${borderColor} shadow-lg rounded-full p-3 transition-all duration-200 group-hover:scale-110 group-active:scale-95`}
        >
          <div
            className={`absolute animate-ping rounded-full h-4 w-4 ${pingColor}`}
          ></div>
          {isAbove ? (
            <ArrowUpIcon className="h-4 w-4 text-gray-700 dark:text-gray-200" />
          ) : (
            <ArrowDownIcon className="h-4 w-4 text-gray-700 dark:text-gray-200" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
