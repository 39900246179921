import React from 'react';
import { KeyIcon } from '@heroicons/react/24/outline';

export const SharedGenerationsPrompt = ({ onAddApiKey }) => {
  return (
    <div className="flex flex-col items-center justify-center p-4 mt-4 border border-yellow-200 dark:border-yellow-900 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
      <KeyIcon className="h-8 w-8 text-yellow-500 dark:text-yellow-400 mb-2" />
      <h3 className="text-sm font-medium text-yellow-800 dark:text-yellow-200">
        Shared Generations Limit Reached
      </h3>
      <p className="text-xs text-yellow-600 dark:text-yellow-300 text-center mt-1">
        Add your OpenAI API key to continue generating suggestions
      </p>
      <button
        onClick={onAddApiKey}
        className="mt-3 px-4 py-2 text-xs font-medium text-yellow-700 dark:text-yellow-200 bg-yellow-100 dark:bg-yellow-900/40 hover:bg-yellow-200 dark:hover:bg-yellow-900/60 rounded-md transition-colors duration-200"
      >
        Add API Key
      </button>
    </div>
  );
};