import React, { useState, useCallback, useRef } from "react";
import TranscriptionService from "./TranscriptionService";
import TextSimilarity from "./TextSimilarity";
import { MicrophoneIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import AlertModal from "./AlertModal";

const DualTranscriptionManager = ({
  setupCompleted,
  currentTopicIndex,
  onTranscript,
  cooldown,
  hasGeneratedInitially,
  onInit,
  onStateChange,
  handleGenerateClick,
  micActive,
  setMicActive,
  tabActive,
  setTabActive,
  isStarting,
  setIsStarting,
  showEarphonesModal,
  setShowEarphonesModal,
  initiateStartProcess,
  micServiceRef,
  tabServiceRef,
  lastProcessedRef,
}) => {
  // Initialize with settings optimized for same-audio detection
  const sharedTextSimilarity = useRef(
    new TextSimilarity({
      minSimilarityThreshold: 0.85,
      timeWindowMs: 1000,
      processingDelayMs: 200,
    })
  );

  const handleTranscriptWithSource = useCallback(
    async (text, source) => {
      try {
        const currentTime = Date.now();
        if (!text.trim()) return;

        const result = await sharedTextSimilarity.current.analyzeTranscript(
          text,
          source.metadata.source,
          currentTime
        );

        if (result.isDuplicate && source.metadata.source === "microphone") {
          console.log("Skipping microphone duplicate of participant speech:", {
            text,
            similarity: result.details.matchPercentage,
          });
          return;
        }

        if (source.metadata.source === "tab-audio") {
          onTranscript(text, source);
          return;
        }

        onTranscript(text, source);
      } catch (error) {
        console.error("Error processing transcript:", error);
      }
    },
    [onTranscript]
  );

  const combinedMethodsRef = useCallback(
    (micMethods, tabMethods) => {
      if (micMethods) micServiceRef.current = micMethods;
      if (tabMethods) tabServiceRef.current = tabMethods;

      if (onInit) {
        onInit({
          isListening: () => micActive || tabActive,
          restartListening: () => {
            if (micActive && micServiceRef.current)
              micServiceRef.current.restartListening();
            if (tabActive && tabServiceRef.current)
              tabServiceRef.current.restartListening();
          },
        });
      }
    },
    [micActive, tabActive, onInit]
  );

  const handleStateChange = useCallback(
    (isListening, source) => {
      if (source === "microphone") {
        setMicActive(isListening);
      } else {
        setTabActive(isListening);
      }
      onStateChange?.(micActive || tabActive);
    },
    [micActive, tabActive, onStateChange]
  );

  const startBothSources = useCallback(async () => {
    if (!setupCompleted || isStarting) return;

    setIsStarting(true);
    try {
      if (tabServiceRef.current && !tabActive) {
        await tabServiceRef.current.startListening();
      }

      await new Promise((resolve) => setTimeout(resolve, 100));

      if (micServiceRef.current && !micActive) {
        await micServiceRef.current.startListening();
      }
    } catch (error) {
      console.error("Error starting audio sources:", error);
    } finally {
      setIsStarting(false);
    }
  }, [setupCompleted, micActive, tabActive, isStarting]);

  const stopBothSources = useCallback(() => {
    if (micServiceRef.current) {
      micServiceRef.current.handleStop();
    }
    if (tabServiceRef.current) {
      tabServiceRef.current.handleStop();
    }
  }, []);

  const isAnySourceActive = micActive || tabActive;

  const renderHighlightIndicators = () => (
    <div className="bottom-4 right-4 flex items-center space-x-3 text-xs text-gray-500 justify-end p-6 pt-2">
      <div className="flex items-center space-x-1">
        <div className="w-3 h-3 rounded-full bg-blue-300" />
        <span>Questions</span>
      </div>
      <div className="flex items-center space-x-1">
        <div className="w-3 h-3 rounded-full bg-green-300" />
        <span>Notes</span>
      </div>  
      <div className="flex items-center space-x-1">
        <div className="w-3 h-3 rounded-full bg-rose-300" />
        <span>Logic</span>
      </div>
      <div className="flex items-center space-x-1">
        <div className="w-3 h-3 rounded-full bg-purple-300" />
        <span>Insights</span>
      </div>
      <div className="flex items-center space-x-1">
        <div className="w-3 h-3 rounded-full bg-yellow-300" />
        <span>Highlights</span>
      </div>
    </div>
  );

  return (
    <div className="space-y-4 px-8 lg:px-0">
      {/* {renderHighlightIndicators()} */}
      <AlertModal
        isOpen={showEarphonesModal}
        onClose={() => setShowEarphonesModal(false)}
        title="Important: Use Earphones"
        message="Please use earphones/headphones. Without them, your microphone will pick up speaker audio, causing echo and reducing transcription quality."
        confirmText="Understood"
        cancelText="Cancel"
        type="info"
        confirmButtonStyle="primary"
        onConfirm={() => {
          setShowEarphonesModal(false);
          startBothSources();
        }}
      />

      <div className="hidden">
        <TranscriptionService
          ref={micServiceRef}
          setupCompleted={setupCompleted}
          currentTopicIndex={currentTopicIndex}
          onTranscript={(text) =>
            handleTranscriptWithSource(text, {
              metadata: {
                speaker: "MICROPHONE",
                source: "microphone",
              },
            })
          }
          onInit={(methods) => combinedMethodsRef(methods, null)}
          cooldown={cooldown}
          hasGeneratedInitially={hasGeneratedInitially}
          onStateChange={(state) => handleStateChange(state, "microphone")}
          handleGenerateClick={handleGenerateClick}
          audioSource="microphone"
          label="Interviewer Audio"
          otherSourceActive={tabActive}
          textSimilarityInstance={sharedTextSimilarity.current}
        />
        <TranscriptionService
          ref={tabServiceRef}
          setupCompleted={setupCompleted}
          currentTopicIndex={currentTopicIndex}
          onTranscript={(text) =>
            handleTranscriptWithSource(text, {
              metadata: {
                speaker: "TAB AUDIO",
                source: "tab-audio",
              },
            })
          }
          onInit={(methods) => combinedMethodsRef(null, methods)}
          cooldown={cooldown}
          hasGeneratedInitially={hasGeneratedInitially}
          onStateChange={(state) => handleStateChange(state, "tab-audio")}
          handleGenerateClick={handleGenerateClick}
          audioSource="tab-audio"
          label="Participant Audio"
          otherSourceActive={micActive}
          textSimilarityInstance={sharedTextSimilarity.current}
        />
      </div>
    </div>
  );
};

export default DualTranscriptionManager;