// useAppEffects.js
import { useEffect, useRef } from "react";

// Custom hook for handling all App component useEffects
const useAppEffects = ({
  availableLineCount,
  setAvailableLineCount,
  topics,
  setTopics,
  mainTopic,
  transcriptionByTopic,
  suggestionsByTopic,
  setSuggestionsByTopic,
  currentTopicIndex,
  cooldown,
  cooldownDuration,
  setCooldown,
  setCooldownTime,
  error,
  setError,
  warning,
  setWarning,
  contextDocument,
  checkSetupCompletion,
  setIsValidApiKey,
  isValidApiKey,
  setSetupCompleted,
  setupCompleted,
  OPENAI_CONFIG,
  user,
  isSubscribed,
  generateFallacies,
  generateNuggets,
  generateQuestions,
  setGenerateFallacies,
  setGenerateNuggets,
  setGenerateQuestions,
  generateNotes,
  setGenerateNotes,
}) => {
  const availableLineCountRef = useRef(availableLineCount);

  // Add this new effect after the other localStorage effects
  useEffect(() => {
    // If user is not subscribed, force premium features off
    if (!user || !isSubscribed) {
      if (generateFallacies && typeof setGenerateFallacies === 'function') {
        setGenerateFallacies(false);
        localStorage.setItem("generateFallacies", "false");
      }
      if (generateNuggets && typeof setGenerateNuggets === 'function') {
        setGenerateNuggets(false);
        localStorage.setItem("generateNuggets", "false");
      }
    }
  }, [
    user, 
    isSubscribed, 
    generateFallacies, 
    generateNuggets, 
    setGenerateFallacies, 
    setGenerateNuggets
  ]);

  useEffect(() => {
    availableLineCountRef.current = availableLineCount;
  }, [availableLineCount]);

  // Ensure at least one topic is set
  useEffect(() => {
    if (topics.length === 0) {
      setTopics(["John Appleseed", "Frank Zappa", "Miles Davis"]);
    }
  }, [topics, setTopics]);

  // Save topics to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("topics", JSON.stringify(topics));
  }, [topics]);

  // Save generate options to localStorage
  useEffect(() => {
    localStorage.setItem(
      "generateQuestions",
      JSON.stringify(generateQuestions)
    );
  }, [generateQuestions]);

  useEffect(() => {
    localStorage.setItem(
      "generateFallacies",
      JSON.stringify(generateFallacies)
    );
  }, [generateFallacies]);

  useEffect(() => {
    localStorage.setItem("generateNotes", JSON.stringify(generateNotes));
  }, [generateNotes]);

  useEffect(() => {
    localStorage.setItem("generateNuggets", JSON.stringify(generateNuggets));
  }, [generateNuggets]);

  // Save mainTopic to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("mainTopic", mainTopic);
  }, [mainTopic]);

  // Save transcriptionByTopic whenever it changes
  useEffect(() => {
    if (transcriptionByTopic) {
      // Preserve manual highlight metadata when saving transcription
      const updatedTranscription = Object.keys(transcriptionByTopic).reduce(
        (acc, topicIndex) => {
          acc[topicIndex] = transcriptionByTopic[topicIndex].map((entry) => ({
            ...entry,
            isHighlight: entry.isHighlight || false,
            type: entry.type || null,
            generated: entry.generated || false,
          }));
          return acc;
        },
        {}
      );

      localStorage.setItem(
        "transcriptionByTopic",
        JSON.stringify(updatedTranscription)
      );
    }
  }, [transcriptionByTopic]);

  // Save suggestionsByTopic whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "suggestionsByTopic",
      JSON.stringify(suggestionsByTopic)
    );
  }, [suggestionsByTopic]);

  // Initialize or resume cooldown on page load
  useEffect(() => {
    const savedCooldownStart = localStorage.getItem("cooldownStartTime");
    if (savedCooldownStart) {
      const elapsed = (Date.now() - parseInt(savedCooldownStart, 10)) / 1000;
      if (elapsed < cooldownDuration) {
        setCooldown(true);
        setCooldownTime(cooldownDuration - Math.floor(elapsed));
      } else {
        localStorage.removeItem("cooldownStartTime");
      }
    }
  }, [cooldownDuration, setCooldown, setCooldownTime]);

  // Cooldown countdown logic
  useEffect(() => {
    if (cooldown) {
      const interval = setInterval(() => {
        setCooldownTime((prevTime) => {
          if (prevTime <= 1) {
            setCooldown(false);
            clearInterval(interval);
            localStorage.removeItem("cooldownStartTime"); // Clear start time after cooldown
            return cooldownDuration;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [cooldown, cooldownDuration, setCooldown, setCooldownTime]);

  // Error message timeout
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
    if (warning) {
      const timer = setTimeout(() => {
        setWarning("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, setError, warning, setWarning]);

  // Check setup completion whenever `contextDocument` or `topics` change
  useEffect(() => {
    checkSetupCompletion();
  }, [contextDocument, topics, checkSetupCompletion]);

  useEffect(() => {
    // Load initial setup status and API key
    const storedSetupCompleted = localStorage.getItem("setupCompleted");
    const storedApiKey = localStorage.getItem("openai_api_key");

    // Check if BOTH setupCompleted and API key exist
    if (storedSetupCompleted === "true" && storedApiKey) {
      console.log("Setup completed and API key found in localStorage");
      setSetupCompleted(true);
      setIsValidApiKey(true);
    }

    // Update localStorage whenever setupCompleted or isValidApiKey changes
    localStorage.setItem("setupCompleted", setupCompleted);
    if (isValidApiKey) {
      localStorage.setItem("openai_api_key", OPENAI_CONFIG.API_KEY);
    }
  }, [setupCompleted, isValidApiKey, OPENAI_CONFIG.API_KEY]); // Include OPENAI_CONFIG.API_KEY

  const currentTopicIndexRef = useRef(currentTopicIndex);

  useEffect(() => {
    currentTopicIndexRef.current = currentTopicIndex;
  }, [currentTopicIndex]);

  return { availableLineCountRef, currentTopicIndexRef };
};

export default useAppEffects;
