import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authContext';

export const SuccessPage = () => {
  const { refreshSubscription } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSuccess = async () => {
      await refreshSubscription();
      setTimeout(() => navigate('/'), 3000);
    };
    handleSuccess();
  }, [refreshSubscription, navigate]);

  return (
    <div className="max-w-2xl mx-auto p-4 text-center">
      <h1 className="text-2xl font-bold mb-4">Subscription Successful!</h1>
      <p>Redirecting you back to the app...</p>
    </div>
  );
};