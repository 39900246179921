import { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const useSharedGenerations = (hasOwnApiKey) => {
  const [remainingGenerations, setRemainingGenerations] = useState(null);
  const [generationsError, setGenerationsError] = useState(null);
  
  useEffect(() => {
    if (!hasOwnApiKey) {
      const functions = getFunctions();
      const getCurrentGenerations = httpsCallable(functions, 'getCurrentGenerations');
      getCurrentGenerations({ hasOwnApiKey }).then((result) => {
        if (result.data.success && !result.data.usingOwnKey) {
          setRemainingGenerations(result.data.remainingGenerations);
        }
      });
    }
  }, [hasOwnApiKey]);
  
  const checkAndIncrementCounter = async (generationSuccess) => {
    if (hasOwnApiKey) {
      return true;
    }

    try {
      const functions = getFunctions();
      const manageDailyGenerations = httpsCallable(functions, 'manageDailyGenerations');
      const result = await manageDailyGenerations({ 
        hasOwnApiKey,
        generationSuccess 
      });
      
      if (result.data.success) {
        if (!result.data.usingOwnKey) {
          setRemainingGenerations(result.data.remainingGenerations);
        }
        return true;
      } else {
        setGenerationsError(result.data.error);
        return false;
      }
    } catch (error) {
      setGenerationsError('Failed to check generation limit');
      return false;
    }
  };
  
  return {
    remainingGenerations,
    generationsError,
    checkAndIncrementCounter
  };
};