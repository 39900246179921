import React, { useEffect, useState } from 'react';
import { SunIcon, MoonIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline';

const DarkModeToggle = ({ onThemeChange }) => {
  const [theme, setTheme] = useState('system'); // Changed from isDark boolean to theme string

  useEffect(() => {
    // Get initial theme
    const savedTheme = localStorage.theme || 'system';
    setTheme(savedTheme);
    
    // Apply theme
    applyTheme(savedTheme);

    // Add system theme listener
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      if (theme === 'system') {
        applyTheme('system');
      }
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [theme]);

  const applyTheme = (newTheme) => {
    if (newTheme === 'dark' || (newTheme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : theme === 'dark' ? 'system' : 'light';
    localStorage.theme = newTheme;
    setTheme(newTheme);
    applyTheme(newTheme);
    if (onThemeChange) onThemeChange(newTheme);
  };

  const getIcon = () => {
    switch (theme) {
      case 'light':
        return <SunIcon className="h-4 w-4" />;
      case 'dark':
        return <MoonIcon className="h-4 w-4" />;
      default:
        return <ComputerDesktopIcon className="h-4 w-4" />;
    }
  };

  const getTitle = () => {
    switch (theme) {
      case 'light':
        return 'Light Mode';
      case 'dark':
        return 'Dark Mode';
      default:
        return 'System Theme';
    }
  };

  return (
    <button
      onClick={toggleTheme}
      className="p-1.5 rounded-md text-gray-600 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-neutral-200"
      aria-label={getTitle()}
      title={getTitle()}
    >
      {getIcon()}
    </button>
  );
};

export default DarkModeToggle;