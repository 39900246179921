import React, { useState, useEffect } from "react";
import { EyeIcon, EyeSlashIcon, KeyIcon, LockClosedIcon } from "@heroicons/react/24/outline";

const APIKeyManager = ({ onApiKeyChange }) => {
  const [apiKey, setApiKey] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const storedKey = localStorage.getItem("openai_api_key");
    if (storedKey) {
      setApiKey(storedKey);
      onApiKeyChange(storedKey, true);
    }
  }, [onApiKeyChange]);

  const handleApiKeyChange = (e) => {
    const newKey = e.target.value.trim();
    setApiKey(newKey);
    const isValid = newKey !== "";
    localStorage.setItem("openai_api_key", newKey);
    onApiKeyChange(newKey, isValid);
    setSaved(true);
    setTimeout(() => setSaved(false), 2000);
  };

  return (
    <section className="space-y-4">
      <div className="flex items-center justify-between text-gray-700 dark:text-neutral-200 border-b border-neutral-300 dark:border-neutral-600 pb-2">
        <div className="flex items-center space-x-2">
          <KeyIcon className="h-5 w-5" />
          <h2 className="font-medium">OpenAI API Key
            {/* <span className="text-red-500 ml-1">*</span> */}
          </h2>
        </div>
        <div className="flex items-center text-sm text-gray-500 dark:text-neutral-400">
          <LockClosedIcon className="h-4 w-4 mr-1" />
          <span>Stored locally only</span>
        </div>
      </div>

      <div className="space-y-3">
        {!apiKey && (
          // <div className="rounded-lg border border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/20 p-3">
          //   <p className="text-sm text-green-700 dark:text-green-400">
          //     Enter your own OpenAI API key for unlimited generations
          //   </p>
          // </div>
          <div className="p-0">
            <p className="text-sm text-black dark:text-white">
              Enter your own OpenAI API key for unlimited generations
            </p>
          </div>
        )}

        <div className="relative">
          <input
            type={showKey ? "text" : "password"}
            className="border bg-white dark:bg-neutral-800 border-neutral-300 dark:border-neutral-600 p-3 pr-20 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 focus:border-transparent transition text-neutral-700 dark:text-neutral-300"
            placeholder="sk-..."
            value={apiKey}
            onChange={handleApiKeyChange}
          />
          <button
            onClick={() => setShowKey(!showKey)}
            className="absolute right-3 top-1/2 -translate-y-1/2 p-1.5 text-gray-400 hover:text-gray-600 dark:text-neutral-500 dark:hover:text-neutral-300"
          >
            {showKey ? (
              <EyeSlashIcon className="h-5 w-5" />
            ) : (
              <EyeIcon className="h-5 w-5" />
            )}
          </button>
        </div>

        {saved && (
          <div className="rounded-lg border border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/20 p-3">
            <p className="text-sm text-green-700 dark:text-green-400">
              API key saved to local storage
            </p>
          </div>
        )}

        <div className="bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg p-3">
          <p className="text-sm text-blue-700 dark:text-blue-300">
            Your API key is stored securely in your browser's local storage and is only used to make API calls to OpenAI.
            It never leaves your device or gets sent to our servers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default APIKeyManager;
