import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  connectAuthEmulator
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// // Debug logging
// console.log('Firebase config:', {
//   authDomain: firebaseConfig.authDomain,
//   projectId: firebaseConfig.projectId
// });

// console.log('Complete Firebase config:', {
//   ...firebaseConfig,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
// });

// console.log('API Key length:', process.env.REACT_APP_FIREBASE_API_KEY?.length);
// console.log('Auth Domain exists:', !!process.env.REACT_APP_FIREBASE_AUTH_DOMAIN);

let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  console.error('Firebase initialization error:', error);
  throw error;
}

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    console.log('Attempting Google sign in...');
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const result = await signInWithPopup(auth, provider);
    console.log('Sign in successful');
    return result.user;
  } catch (error) {
    console.error('Google sign in error:', {
      code: error.code,
      message: error.message,
      customData: error.customData
    });
    throw error;
  }
};

export const signOutUser = () => signOut(auth);

export const getCurrentUser = () => auth.currentUser;

export const onAuthStateChange = (callback) => {
  return onAuthStateChanged(auth, callback);
};