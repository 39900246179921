import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const OPENAI_CONFIG = {
  API_URL: "https://api.openai.com/v1/chat/completions",
  MODELS: {
    GPT_3_5_TURBO: "gpt-3.5-turbo",
  },
  get API_KEY() {
    const storedKey = localStorage.getItem('openai_api_key');
    if (storedKey && storedKey.trim() !== '') {
      return storedKey;
    }
    return process.env.REACT_APP_OPENAI_API_KEY || '';
  },
  set API_KEY(value) {
    if (value && value !== process.env.REACT_APP_OPENAI_API_KEY) {
      localStorage.setItem('openai_api_key', value);
    } else if (!value) {
      localStorage.removeItem('openai_api_key');
    }
  }
};

export const generateSuggestions = async ({
  currentTopicIndex,
  transcriptionByTopic,
  mainTopic,
  topics,
  contextDocument,
  generateQuestions,
  generateFallacies,
  generateNuggets,
  generateNotes,
  minimumLinesNeeded,
  numberOfLinesToSend,
  isSubscribed,
}) => {
  // Use shared API key if no personal key is set
  const apiKey = OPENAI_CONFIG.API_KEY;

  if (!apiKey || apiKey.trim() === '') {
    throw new Error('No API key available. Please check environment variables or add your own API key.');
  }

  try {
    const topicTranscript = transcriptionByTopic[currentTopicIndex] || [];

    // Filter out generated or done lines and limit to numberOfLinesToSend
    const formattedTranscript = topicTranscript
      .filter((entry) => !entry.generated && !entry.done)
      .slice(-numberOfLinesToSend)
      .map((line) => ({
        id: line.id || uuidv4(),
        text: line.text || "",
        speaker: line.speaker?.toUpperCase() || "TAB AUDIO",
        generated: line.generated ?? false,
      }));

    if (formattedTranscript.length < minimumLinesNeeded) {
      throw new Error(
        `Insufficient lines: ${formattedTranscript.length} lines available, minimum ${minimumLinesNeeded} required.`
      );
    }

    const uuidToNumericMap = {};
    let numericCounter = 1;

    const mappedTranscript = formattedTranscript.map((entry) => {
      const entryUUID = entry.id || uuidv4();
      const tempId = numericCounter++;
      uuidToNumericMap[tempId] = entryUUID;
      return { ...entry, id: entryUUID, tempId };
    });

    // Format transcript with speaker labels for OpenAI
    const formattedForOpenAI = mappedTranscript.map(
      ({ tempId, text, speaker, generated }) => {
        // Strip any existing speaker labels
        const cleanText = text.replace(/^\s*\[(TAB AUDIO|MICROPHONE)\]:\s*/i, '');

        // Ensure speaker is uppercase and consistent
        const speakerLabel = speaker?.toUpperCase() === "MICROPHONE" ? "MICROPHONE" : "TAB AUDIO";
        const formattedText = `[${speakerLabel}]: ${cleanText}`;

        return {
          id: tempId,
          text: formattedText,
          speaker: speakerLabel,  // Use the normalized speaker label
          generated,
        };
      }
    );

    // Initialize arrays to track what types we'll generate
    const enabledTypes = [];
    if (generateQuestions) enabledTypes.push("question");
    if (generateFallacies && isSubscribed) enabledTypes.push("fallacy");
    if (generateNuggets && isSubscribed) enabledTypes.push("nugget");
    if (generateNotes) enabledTypes.push("note");
    if (enabledTypes.length === 0) {
      throw new Error("No suggestion types enabled. Please enable at least one type (questions, notes, fallacies, or nuggets).");
    }

    const systemPrompt = `You are a research assistant analyzing interview transcripts. Your goal is to provide valuable insights based on the context:

    Main topic: "${mainTopic}"
    Topics: ${topics.join(", ")}
    Current topic: "${topics[currentTopicIndex]}"
    Context: "${contextDocument}"

    The transcript contains dialogue between an MICROPHONE and a TAB AUDIO, marked with corresponding labels.
    When analyzing, consider the role and context of who is speaking.

    IMPORTANT: If you cannot find a genuinely valuable insight matching the requirements, return:
    {"Insight": null, "Quoted Sentence ID": null}

    Return ONLY a JSON with:
    - "Insight": Your analysis or null
    - "Quoted Sentence ID": Numeric ID of referenced line or null`;

    // Try each enabled type in order: fallacies first, then others
    for (const suggestionType of enabledTypes) {
      try {
        let taskPrompt = "";

        switch (suggestionType) {
          case "fallacy":
            taskPrompt = `Analyze the transcript for potential logical fallacies or questionable reasoning patterns. Look for both explicit and implicit reasoning errors.

Requirements:
- Identify reasoning patterns that could lead to flawed conclusions
- Consider both direct statements and implied logic
- Look for assumptions, generalizations, and causal claims
- Format: "Fallacy Type: Specific Pattern | Potential Impact"

Common Patterns to Watch For:
- Sampling Bias: Drawing conclusions from non-representative data
- Correlation/Causation: Assuming one thing caused another
- Hasty Generalization: Drawing broad conclusions from limited data
- Appeal to Authority: Relying too heavily on expert/sales recommendations
- False Equivalence: Treating different situations as the same
- Confirmation Bias: Focusing on data that confirms existing beliefs

Example inputs and outputs:
1. Input: [{"id": 1, "text": "[MICROPHONE]: Initially, we focused on 'high-value partners' provided by sales teams, but it created a bias in feedback."}]
   Output: {"Insight": "Sampling Bias: Relying on sales-selected partners skewed feedback | Led to non-representative insights", "Quoted Sentence ID": 1}

2. Input: [{"id": 1, "text": "[MICROPHONE]: Partners who saw their feedback in updates became more responsive."}]
   Output: {"Insight": "Post Hoc Fallacy: Assuming feedback implementation directly caused engagement | May oversimplify causation", "Quoted Sentence ID": 1}

Your turn - Look for both explicit and subtle reasoning patterns that could impact decision-making. Consider the full context of statements.`;
            break;

          case "nugget":
            taskPrompt = `Analyze the transcript for valuable insights and key points about ${mainTopic} and ${topics[currentTopicIndex]}. 

Requirements:
- Must identify concrete, actionable insights
- Must be based on explicit statements or clear patterns
- Must have business or research value
- Format: "Key Finding: Specific Detail | Business Impact"

Common Patterns to Watch For:
- User Behavior: How users interact or make decisions
- Pain Points: Clear challenges or frustrations
- Success Patterns: What's working well
- Process Insights: How things are done
- Unexpected Findings: Surprising or counter-intuitive points
- Improvement Areas: Opportunities for enhancement

Example inputs and outputs:
1. Input: [{"id": 1, "text": "[TAB AUDIO]: We've noticed that response rates improved when we switched from sales-recommended lists to direct platform outreach."}]
   Output: {"Insight": "Direct Outreach Success: Platform-based outreach improved response rates | Better engagement strategy identified", "Quoted Sentence ID": 1}

2. Input: [{"id": 1, "text": "[MICROPHONE]: Partners express concerns about AI accuracy but appreciate manual override options."}]
   Output: {"Insight": "AI Control Balance: Manual overrides increase AI tool acceptance | Key to user adoption", "Quoted Sentence ID": 1}

Your turn - Look for specific, valuable insights that could impact decisions or strategy. If no clear insight exists, return null.`;
            break;

          case "question":
            taskPrompt = `Analyze the transcript for missed opportunities to dive deeper into valuable answers. 

Requirements:
1. Must identify a clear question-answer sequence where:
   - A question was explicitly asked
   - An answer was provided
   - The answer contains valuable information
   - The conversation moved to a different topic without exploring the answer

2. The answer must be:
   - Substantive enough to warrant follow-up
   - Not fully explored in subsequent dialogue
   - Relevant to ${mainTopic} or ${topics[currentTopicIndex]}

3. Your follow-up question must:
   - Use "you" to maintain conversational tone
   - Be brief (max 7 words)
   - Directly relate to the identified answer
   - Aim to uncover specific details

Example Good Sequences:
Input: [
  {"id": 1, "text": "[MICROPHONE]: What challenges have you faced with implementation?"},
  {"id": 2, "text": "[TAB AUDIO]: We've had several security concerns"},
  {"id": 3, "text": "[MICROPHONE]: And what about your team size?"}
]
Output: {"Insight": "What security concerns worried you most?", "Quoted Sentence ID": 2}

Example Poor Sequences:
Input: [
  {"id": 1, "text": "[MICROPHONE]: How long have you used the platform?"},
  {"id": 2, "text": "[TAB AUDIO]: About two years"},
  {"id": 3, "text": "[MICROPHONE]: And how has your experience been?"}
]
Output: {"Insight": null, "Quoted Sentence ID": null}

Your turn - Only generate a follow-up question when:
1. There's a clear question-answer sequence
2. The answer contains valuable information
3. The conversation moved on without proper exploration
4. The potential follow-up could yield meaningful insights

If these criteria aren't met, return null.`;
            break;

          case "note":
            taskPrompt = `Create an extremely brief, single-sentence summary of the conversation up to this point.

Requirements:
- MUST be under 10 words
- Capture the core topic only
- Use simple, direct language
- Format: "Topic: Main Point"

Example inputs and outputs:
1. Input: [
  {"id": 1, "text": "[MICROPHONE]: How do you handle user data?"},
  {"id": 2, "text": "[TAB AUDIO]: We use encryption for storage"},
  {"id": 3, "text": "[MICROPHONE]: And what about access controls?"},
  {"id": 4, "text": "[TAB AUDIO]: We're implementing role-based permissions"}
]
Output: {"Insight": "Security: Encryption and access controls being implemented", "Quoted Sentence ID": 4}

2. Input: [
  {"id": 1, "text": "[MICROPHONE]: When did you start the project?"},
  {"id": 2, "text": "[TAB AUDIO]: Development began last March"},
  {"id": 3, "text": "[MICROPHONE]: And the team size?"},
  {"id": 4, "text": "[TAB AUDIO]: Started with 3, now at 10"}
]
Output: {"Insight": "Team Growth: From 3 to 10 since March", "Quoted Sentence ID": 4}

Your turn - Create ONE extremely concise summary. Think of it as a quick note you'd jot down to remember the main point.

Remember: Brevity is crucial - if you can't say it quickly, you're including too much detail.`;
            break;
        }

        console.log("Sending to OpenAI:", {
          transcript: formattedForOpenAI,
          systemPrompt,
          taskPrompt,
          model: OPENAI_CONFIG.MODELS.GPT_3_5_TURBO
        });

        const response = await axios.post(
          OPENAI_CONFIG.API_URL,
          {
            model: OPENAI_CONFIG.MODELS.GPT_3_5_TURBO,
            messages: [
              { role: "system", content: systemPrompt },
              { role: "user", content: JSON.stringify(formattedForOpenAI) },
              { role: "user", content: taskPrompt },
            ],
            temperature: suggestionType === "fallacy" ? 0.8 : 0.4,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${OPENAI_CONFIG.API_KEY}`,
            },
          }
        );

        let rawContent = response.data.choices[0].message.content;
        if (rawContent.startsWith("```json")) {
          rawContent = rawContent.replace(/```json|```/g, "").trim();
        }
        const parsedContent = JSON.parse(rawContent);

        if (parsedContent.Insight && parsedContent["Quoted Sentence ID"]) {
          const uuidQuotedSentenceID =
            uuidToNumericMap[parsedContent["Quoted Sentence ID"]];

          if (uuidQuotedSentenceID) {
            const suggestion = {
              id: uuidQuotedSentenceID,
              Insight: parsedContent["Insight"],
              "Quoted Sentence ID": uuidQuotedSentenceID,
              "Quoted Sentence": formattedTranscript
                .find((sentence) => sentence.id === uuidQuotedSentenceID)
                ?.text.trim(),
              type: suggestionType,
            };

            return {
              questions: suggestionType === "question" ? [suggestion] : [],
              fallacies: suggestionType === "fallacy" ? [suggestion] : [],
              nuggets: suggestionType === "nugget" ? [suggestion] : [],
              notes: suggestionType === "note" ? [suggestion] : [],
            };
          }
        }
      } catch (error) {
        console.error(`Error generating ${suggestionType}:`, {
          error: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        throw new Error(`Failed to generate ${suggestionType}: ${error.message}${error.response?.data ? ` - ${JSON.stringify(error.response.data)}` : ''
          }`);
      }
    }

    // If no suggestions were generated, throw a descriptive error
    return {
      success: false,
      message: `No new suggestions found for: ${enabledTypes.join(", ")}`,
      type: "info",
      suggestions: {
        questions: [],
        fallacies: [],
        nuggets: [],
        notes: []
      }
    };
  } catch (error) {
    // Log the full error details
    console.error("Suggestion generation failed:", {
      error: error.message,
      stack: error.stack,
      response: error.response?.data,
      status: error.response?.status
    });

    // Throw a user-friendly error with detailed information
    throw new Error(`Failed to generate suggestions: ${error.message}${error.response?.data ? ` - ${JSON.stringify(error.response.data)}` : ''
      }`);
  }
};