// TranscriptLogging.js
class TranscriptLogger {
  constructor(prefix = "") {
    this.prefix = prefix;
    this.transcriptHistory = [];
    this.MAX_HISTORY = 20;
    this.debugMode = true;
  }

  formatTranscript(transcript) {
    return {
      text: transcript.text,
      timestamp: Date.now(),
      metadata: {
        wordCount: (transcript.text || "").split(" ").length,
        characterCount: (transcript.text || "").length,
      },
    };
  }

  logAnalysis(result, audioAnalysis, similarityAnalysis) {
    if (!this.debugMode) return;

    const analysis = {
      timestamp: Date.now(),
      transcriptDetails: {
        text: result.text,
        wordCount: result.text.split(" ").length,
        source: this.prefix,
      },
      audioAnalysis: audioAnalysis
        ? {
            characteristics: audioAnalysis.characteristics || {},
            confidence: audioAnalysis.characteristics?.confidence || 0,
            isDirectSpeech:
              audioAnalysis.characteristics?.isDirectSpeech || false,
            isLeakedAudio:
              audioAnalysis.characteristics?.isLeakedAudio || false,
          }
        : null,
      similarityAnalysis: {
        isDuplicate: similarityAnalysis?.isDuplicate || false,
        matchCount: similarityAnalysis?.allMatches?.length || 0,
        bestMatchDetails: similarityAnalysis?.bestMatch
          ? {
              similarity: similarityAnalysis.bestMatch.similarity,
              timeDiff: similarityAnalysis.bestMatch.timeDiff,
              source: similarityAnalysis.bestMatch.source,
            }
          : null,
        comparisonDetails: similarityAnalysis?.details
          ? {
              matchPercentage: similarityAnalysis.details.matchPercentage || 0,
              matchedWords: similarityAnalysis.details.matches?.length || 0,
              mismatchedWords:
                similarityAnalysis.details.mismatches?.length || 0,
              addedWords: similarityAnalysis.details.additions?.length || 0,
              deletedWords: similarityAnalysis.details.deletions?.length || 0,
            }
          : null,
      },
      historyStats: {
        bufferedTranscripts: this.transcriptHistory.length,
      },
    };

    console.group(`${this.prefix} Transcript Analysis`);
    console.log("Timestamp:", new Date(analysis.timestamp).toISOString());
    console.log("Transcript:", analysis.transcriptDetails);

    if (analysis.audioAnalysis) {
      console.group("Audio Analysis");
      console.log("Characteristics:", analysis.audioAnalysis);
      console.groupEnd();
    }

    console.group("Similarity Analysis");
    console.log("Is Duplicate:", analysis.similarityAnalysis.isDuplicate);
    console.log("Matches Found:", analysis.similarityAnalysis.matchCount);
    if (analysis.similarityAnalysis.bestMatchDetails) {
      console.log("Best Match:", analysis.similarityAnalysis.bestMatchDetails);
    }
    if (analysis.similarityAnalysis.comparisonDetails) {
      console.log(
        "Comparison Details:",
        analysis.similarityAnalysis.comparisonDetails
      );
    }
    console.groupEnd();

    console.log("History Stats:", analysis.historyStats);
    console.groupEnd();

    return analysis;
  }

  addToHistory(transcript) {
    const formattedTranscript = this.formatTranscript(transcript);
    this.transcriptHistory.push(formattedTranscript);

    if (this.transcriptHistory.length > this.MAX_HISTORY) {
      this.transcriptHistory.shift();
    }

    if (this.debugMode) {
      console.log(`${this.prefix} Added to history:`, formattedTranscript);
    }
  }

  getRecentTranscripts(timeWindowMs = 5000) {
    const now = Date.now();
    return this.transcriptHistory.filter(
      (t) => now - t.timestamp <= timeWindowMs
    );
  }

  clearHistory() {
    this.transcriptHistory = [];
    if (this.debugMode) {
      console.log(`${this.prefix} History cleared`);
    }
  }
}

export default TranscriptLogger;
