import React from 'react';

const Switch = ({ 
  type, 
  checked, 
  onChange, 
  isSubscribed = true,
  disabled = false 
}) => {
  const handleChange = () => {
    if (disabled) return;
    
    // Premium features check
    if ((type === 'nugget' || type === 'fallacy') && !isSubscribed) {
      // Handle premium feature toggle attempt
      return;
    }
    
    onChange(!checked);
  };

  return (
    <button
      type="button"
      onClick={handleChange}
      className={`
        relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full 
        border-2 border-transparent transition-colors duration-200 ease-in-out 
        focus:outline-none
        ${disabled || (!isSubscribed && (type === 'nugget' || type === 'fallacy')) 
          ? 'cursor-not-allowed opacity-50' 
          : ''
        }
        ${checked 
          ? type === 'note' || type === 'question'
            ? 'bg-blue-600 dark:bg-blue-500'
            : 'bg-purple-600 dark:bg-purple-500'
          : 'bg-gray-200 dark:bg-neutral-700'
        }
      `}
      disabled={disabled || (!isSubscribed && (type === 'nugget' || type === 'fallacy'))}
      role="switch"
      aria-checked={checked}
    >
      <span
        className={`
          pointer-events-none inline-block h-5 w-5 transform rounded-full 
          bg-white shadow ring-0 transition duration-200 ease-in-out
          ${checked ? 'translate-x-5' : 'translate-x-0'}
        `}
      />
    </button>
  );
};

export default Switch;