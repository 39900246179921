import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();

export const checkSubscriptionStatus = async () => {
  try {
    const checkStatus = httpsCallable(functions, 'checkSubscriptionStatus');
    const result = await checkStatus();
    return result.data.isActive;
  } catch (error) {
    console.error('Error checking subscription status:', error);
    return false;
  }
};

export const createCheckoutSession = async () => {
  try {
    const createSession = httpsCallable(functions, 'createCheckoutSession');
    const result = await createSession();
    console.log('Function result:', result);
    if (!result.data.sessionUrl) {
      throw new Error('No session URL returned');
    }
    return result.data.sessionUrl;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const cancelSub = httpsCallable(functions, 'cancelSubscription');
    const result = await cancelSub();
    return result.data;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

export const createPortalSession = async () => {
  try {
    const createPortal = httpsCallable(functions, 'createPortalSession');
    const result = await createPortal();
    return result.data.url;
  } catch (error) {
    console.error('Error creating portal session:', error);
    throw error;
  }
}; 