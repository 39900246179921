import React, { useCallback, useEffect, useRef, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import ScrollIndicator from "./ScrollIndicator";
import AlertModal from "./AlertModal";
import Highlight from "./Highlight";
import { SuggestionCard } from "./SuggestionCard";
import EditorTooltip from "./EditorTooltip";
import EditorHeader from "./EditorHeader";
import useEditorContentManager from "./useEditorContentManager";

// Helper function
const getPlainText = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || "";
};

const TranscriptEditor = ({
  currentTopicIndex,
  transcriptionByTopic,
  suggestionsByTopic,
  setSuggestionsByTopic,
  setTranscriptionByTopic,
  handleCompleteSuggestion,
  handleDeleteSuggestion,
  setActiveTooltip,
  activeTooltip,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  topics,
  showPrivacyModal,
  setShowPrivacyModal,
}) => {
  const [prevSuggestions, setPrevSuggestions] = useState({});
  const [copySuccess, setCopySuccess] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [lastGenerationPosition, setLastGenerationPosition] = useState(null);
  const editorRef = useRef(null);
  const scrollPositionRef = useRef(0);

  const {
    editor,
    lastSavedContent,
    updateTimeoutRef,
    createValidContent,
    checkForChanges,
    normalizeContent,
    onUpdate
  } = useEditorContentManager({
    currentTopicIndex,
    transcriptionByTopic,
    suggestionsByTopic,
    prevSuggestions,
    setPrevSuggestions,
    setHasUnsavedChanges,
    handleCompleteSuggestion,
    handleDeleteSuggestion,
    Highlight,
    SuggestionCard,
    getPlainText,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  });

  const handleSave = () => {
    if (!editor) return;

    try {
      const content = editor.getHTML();
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const paragraphs = Array.from(doc.body.querySelectorAll("p"));

      // Extract existing highlights
      const existingHighlights = new Map();
      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "text" && node.marks) {
          const mark = node.marks.find((m) => m.type.name === "highlight");
          if (mark) {
            existingHighlights.set(mark.attrs.id, mark);
          }
        }
      });

      // Process paragraphs and retain stable IDs
      const filteredParagraphs = paragraphs
        .map((p, index) => {
          const text = p.textContent.trim();
          if (!text) return null;

          const existingParagraph = transcriptionByTopic[currentTopicIndex]?.find(
            (item) => item.text === text
          );

          const paragraphId = existingParagraph?.id || `p-${Date.now()}-${index}`;
          return { id: paragraphId, text };
        })
        .filter(Boolean);

      // Update state and local storage
      const updatedState = {
        ...transcriptionByTopic,
        [currentTopicIndex]: filteredParagraphs,
      };

      localStorage.setItem("transcriptionByTopic", JSON.stringify(updatedState));
      setTranscriptionByTopic(updatedState);

      // Important: Update the lastSavedContent reference
      lastSavedContent.current = content;
      setHasUnsavedChanges(false);

      // Show success indicator
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 2000);
    } catch (error) {
      console.error("Failed to save:", error);
    }
  };

  const handleAddHighlight = async (paragraphIndex) => {
    if (!editor) return;

    // First save the current content
    await handleSave();

    const paragraphPositions = [];
    let paragraphId = null;

    const sentences = transcriptionByTopic[currentTopicIndex] || [];

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === "paragraph") {
        paragraphPositions.push({ node, pos });
      }
    });

    const targetParagraph = paragraphPositions[paragraphIndex];
    if (!targetParagraph?.node.textContent) return;

    paragraphId =
      sentences[paragraphIndex]?.id ||
      `p-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    const text = targetParagraph.node.textContent;
    const defaultMessage = "Important point to follow up on";

    const highlightSuggestion = {
      id: `highlight-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      type: "manual",
      isHighlight: true,
      "Quoted Sentence ID": paragraphId,
      "Quoted Sentence": targetParagraph.node.textContent,
      done: false,
      text: targetParagraph.node.textContent,
      Insight: defaultMessage,
    };

    setSuggestionsByTopic((prevState) => {
      const newState = { ...prevState };

      if (!newState[currentTopicIndex]) {
        newState[currentTopicIndex] = {
          questions: [],
          fallacies: [],
          nuggets: [],
          notes: [],
          manual: [],
        };
      }

      newState[currentTopicIndex].manual = [
        ...newState[currentTopicIndex].manual,
        highlightSuggestion,
      ];

      localStorage.setItem("suggestionsByTopic", JSON.stringify(newState));
      return newState;
    });

    editor
      .chain()
      .command(({ tr }) => {
        const mark = editor.schema.marks.highlight.create({
          id: paragraphId,
          paragraphId: paragraphId,
          type: "manual",
          isHighlight: true,
          generated: false,
          done: false,
        });

        const from = targetParagraph.pos + 1;
        const to = targetParagraph.pos + targetParagraph.node.nodeSize - 1;

        tr.addMark(from, to, mark);
        return true;
      })
      .run();

    setActiveTooltip(null);
  };

  const handleLineClick = (event) => {
    if (!editor) return;

    // Get the clicked paragraph element
    const paragraph = event.target.closest('p');
    if (!paragraph) {
      setActiveTooltip(null);
      return;
    }

    // Find the index of the clicked paragraph
    const paragraphs = Array.from(editor.view.dom.querySelectorAll('.ProseMirror > p'));
    const clickedIndex = paragraphs.indexOf(paragraph);

    if (clickedIndex !== -1) {
      setActiveTooltip(activeTooltip === clickedIndex ? null : clickedIndex);
    } else {
      setActiveTooltip(null);
    }
  };

  const handleSpeakerSwap = (paragraphIndex) => {
    if (!editor) return;

    const paragraphPositions = [];
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === "paragraph") {
        paragraphPositions.push({ node, pos });
      }
    });

    const targetParagraph = paragraphPositions[paragraphIndex];
    if (!targetParagraph?.node.textContent) return;

    const text = targetParagraph.node.textContent;
    const interviewerPattern = /\s*\[MICROPHONE\]/i;
    const participantPattern = /\s*\[TAB AUDIO\]/i;

    let newText;
    let newSpeakerType;

    const preserveWhitespace = (originalText, label) => {
      const match = originalText.match(/^\s*/);
      const leadingWhitespace = match ? match[0] : "";
      return leadingWhitespace + label + originalText.substring(originalText.indexOf("]") + 1);
    };

    if (interviewerPattern.test(text)) {
      newText = preserveWhitespace(text, "[TAB AUDIO]");
      newSpeakerType = "TAB AUDIO";
    } else if (participantPattern.test(text)) {
      newText = preserveWhitespace(text, "[MICROPHONE]");
      newSpeakerType = "MICROPHONE";
    } else {
      return;
    }

    // First, collect any existing highlight marks on the paragraph
    let existingHighlights = [];
    editor.state.doc.nodesBetween(
      targetParagraph.pos,
      targetParagraph.pos + targetParagraph.node.nodeSize,
      (node, pos) => {
        if (node.marks) {
          const highlights = node.marks.filter(mark => mark.type.name === "highlight");
          highlights.forEach(highlight => {
            existingHighlights.push({
              mark: highlight,
              from: pos,
              to: pos + node.nodeSize
            });
          });
        }
      }
    );

    // Update transcriptionByTopic object
    setTranscriptionByTopic((prevState) => {
      const newState = { ...prevState };
      if (!newState[currentTopicIndex]) {
        newState[currentTopicIndex] = [];
      }
      const currentTopicTranscriptions = [...(newState[currentTopicIndex] || [])];

      currentTopicTranscriptions[paragraphIndex] = {
        ...currentTopicTranscriptions[paragraphIndex],
        id: currentTopicTranscriptions[paragraphIndex]?.id || `p-${paragraphIndex}`,
        text: newText,
        speaker: newSpeakerType.toUpperCase(),
      };

      newState[currentTopicIndex] = currentTopicTranscriptions;
      return newState;
    });

    // Update suggestions while preserving highlight information
    setSuggestionsByTopic((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState || {}));
      const currentSuggestions = newState[currentTopicIndex]?.manual || [];
      currentSuggestions.forEach((highlight) => {
        if (highlight["Quoted Sentence"] === text) {
          highlight["Quoted Sentence"] = newText;
          highlight.Insight = newSpeakerType === "MICROPHONE"
            ? "Manually added highlight | Important interviewer question or statement"
            : "Manually added highlight | Key participant insight or response";
        }
      });
      return newState;
    });

    // Update editor content while preserving highlights
    editor.chain()
      .command(({ tr }) => {
        const from = targetParagraph.pos;
        const to = from + targetParagraph.node.nodeSize;

        // Create new paragraph with updated text
        const newParagraph = editor.schema.nodes.paragraph.create(
          {},
          editor.schema.text(newText)
        );

        // Replace the old paragraph with the new one
        tr.replaceWith(from, to, newParagraph);

        // Reapply all highlight marks
        existingHighlights.forEach(highlight => {
          const adjustedFrom = from + 1; // Account for paragraph node
          const adjustedTo = from + newText.length + 1; // Account for paragraph node and new text length
          tr.addMark(adjustedFrom, adjustedTo, highlight.mark);
        });

        return true;
      })
      .run();

    setHasUnsavedChanges(true);
  };

  // And in the useEffect that updates content:
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!editor) return;

      const doc = editor.view.state.doc;
      let lastPos = null;

      doc.descendants((node, pos) => {
        if (
          node.marks?.some(
            (mark) =>
              mark.type.name === "highlight" &&
              mark.attrs.isNew &&
              !mark.attrs.isHighlight
          )
        ) {
          lastPos = pos;
        }
      });

      if (lastPos !== null) {
        const coords = editor.view.coordsAtPos(lastPos);
        const editorElement = editor.view.dom;
        const topOffset = coords.top + editorElement.scrollTop;

        setLastGenerationPosition(topOffset);

        requestAnimationFrame(() => {
          const newCoords = editor.view.coordsAtPos(lastPos);
          const newTopOffset = newCoords.top + editorElement.scrollTop;
          if (newTopOffset !== topOffset) {
            setLastGenerationPosition(newTopOffset);
          }
        });
      } else {
        setLastGenerationPosition(null);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [suggestionsByTopic, currentTopicIndex, editor]);

  // Add beforeunload event listener for unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
        return e.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  // In TranscriptEditor.js
  useEffect(() => {
    if (!editor) return;

    const currentContent = editor.getHTML();
    const currentPlainText = getPlainText(currentContent);
    const newPlainText = (transcriptionByTopic[currentTopicIndex] || [])
      .map((sentence) => sentence.text)
      .join(" ");

    if (currentPlainText !== newPlainText) {
      // Store current scroll position before update
      // When restoring scroll
      const container = editorRef.current?.querySelector('.transcription-container');
      if (container) {
        container.classList.add('restoring-scroll');
        container.scrollTop = scrollPositionRef.current;
        requestAnimationFrame(() => {
          container.classList.remove('restoring-scroll');
        });
      }

      const { from, to } = editor.state.selection;

      let content = [];
      const sentences = transcriptionByTopic[currentTopicIndex] || [];
      const currentSuggestions = suggestionsByTopic[currentTopicIndex] || {};
      const previousSuggestions = prevSuggestions[currentTopicIndex] || {};

      if (sentences.length === 0) {
        // Add placeholder paragraph when empty
        content = [{
          type: "paragraph",
          attrs: { class: "editor-placeholder" },
          content: [{
            type: "text",
            text: "Start transcribing or copy-paste your transcript here."
          }]
        }];
      } else {
        sentences.forEach((sentence) => {
          const suggestion = Object.values(currentSuggestions)
            .flat()
            .find((s) => s["Quoted Sentence ID"] === sentence.id);

          const prevSuggestion = Object.values(previousSuggestions)
            .flat()
            .find((s) => s["Quoted Sentence ID"] === sentence.id);

          // This createValidContent comes from the hook's return value at the top of the component
          const validContent = createValidContent(
            sentence,
            suggestion,
            prevSuggestion
          );
          content = [...content, ...validContent];
        });
      }

      const tr = editor.state.tr.setMeta("isCardUpdate", true).replaceWith(
        0,
        editor.state.doc.content.size,
        editor.schema.nodeFromJSON({
          type: "doc",
          content: content,
        })
      );

      editor.view.dispatch(tr);

      setPrevSuggestions((prev) => ({
        ...prev,
        [currentTopicIndex]: currentSuggestions,
      }));

      // After the content update, restore scroll position
      requestAnimationFrame(() => {
        try {
          // Restore cursor position
          editor.commands.setTextSelection({
            from: Math.min(from, editor.state.doc.content.size),
            to: Math.min(to, editor.state.doc.content.size),
          });

          // Restore scroll position
          if (container) {
            container.scrollTop = scrollPositionRef.current;
          }
        } catch (error) {
          console.warn("Could not restore editor state:", error);
        }
      });
    }
  }, [
    editor,
    currentTopicIndex,
    transcriptionByTopic,
    suggestionsByTopic,
    createValidContent,
  ]);

  const handleCopy = () => {
    if (editor) {
      navigator.clipboard
        .writeText(editor.getText())
        .then(() => {
          setCopySuccess(true);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  const handleDownload = () => {
    if (editor) {
      const text = editor.getText();
      const element = document.createElement("a");
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = "transcript.txt";
      document.body.appendChild(element);
      element.click();
      setDownloadSuccess(true);
    }
  };

  useEffect(() => {
    if (copySuccess) {
      const timer = setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copySuccess]);

  useEffect(() => {
    if (downloadSuccess) {
      const timer = setTimeout(() => {
        setDownloadSuccess(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [downloadSuccess]);

  useEffect(() => {
    if (!editor) return;

    const doc = editor.view.state.doc;
    let lastPos = null;

    doc.descendants((node, pos) => {
      if (
        node.marks?.some(
          (mark) =>
            mark.type.name === "highlight" &&
            mark.attrs.isNew &&
            !mark.attrs.isHighlight
        )
      ) {
        lastPos = pos;
      }
    });

    if (lastPos !== null) {
      const coords = editor.view.coordsAtPos(lastPos);
      const editorElement = editor.view.dom;

      // Store both the position and the viewport offset
      setLastGenerationPosition({
        top: coords.top + editorElement.scrollTop,
        viewportOffset: window.visualViewport.offsetTop
      });
    } else {
      setLastGenerationPosition(null);
    }
  }, [suggestionsByTopic, currentTopicIndex, editor]);

  useEffect(() => {
    if (!editor) return;

    const currentContent = editor.getHTML();
    const hasChanges = checkForChanges(currentContent, lastSavedContent.current);
    setHasUnsavedChanges(hasChanges);
  }, [editor, checkForChanges]);

  // Also update the onUpdate handler to preserve scroll position
  const handleUpdate = useCallback(() => {
    if (!editor) return;

    // Store scroll position before any content changes
    // When restoring scroll
    const container = editorRef.current?.querySelector('.transcription-container');
    if (container) {
      container.classList.add('restoring-scroll');
      container.scrollTop = scrollPositionRef.current;
      requestAnimationFrame(() => {
        container.classList.remove('restoring-scroll');
      });
    }

    // Existing update logic...
    onUpdate();

    // Restore scroll position after update
    requestAnimationFrame(() => {
      if (container) {
        container.scrollTop = scrollPositionRef.current;
      }
    });
  }, [editor, onUpdate]);

  return (
    <div className="flex flex-col h-[100dvh] lg:pt-5 lg:pb-24">
      <div className="relative flex-1 bg-white dark:bg-neutral-900 lg:rounded-lg shadow-sm border border-neutral-300 dark:border-neutral-600 overflow-hidden flex flex-col">
        <EditorHeader
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={setHasUnsavedChanges}
          saveSuccess={saveSuccess}
          copySuccess={copySuccess}
          downloadSuccess={downloadSuccess}
          onSave={handleSave}
          setShowPrivacyModal={() => setShowPrivacyModal(true)}
          onDownload={handleDownload}
          onCopy={handleCopy}
          topics={topics}
          currentTopicIndex={currentTopicIndex}
        />

        {/* Main content area */}
        <div className="flex-1 relative overflow-hidden flex flex-col">
          <div className="transcription-container flex-1 overflow-y-auto">
            <div className="px-4 py-4 min-h-full">
              <EditorContent
                editor={editor}
                ref={editorRef}
                onClick={handleLineClick}
              />
            </div>
          </div>
        </div>

        {/* Bottom elements */}
        <ScrollIndicator
          lastGenerationPosition={lastGenerationPosition}
          editorRef={editorRef}
        />

        <EditorTooltip
          isVisible={activeTooltip !== null}
          position={(() => {
            if (!editor || activeTooltip === null) return 0;
            const paragraphs = Array.from(
              editor.view.dom.querySelectorAll(".ProseMirror > p")
            );
            return paragraphs[activeTooltip]?.offsetTop || 0;
          })()}
          onSpeakerSwap={() => handleSpeakerSwap(activeTooltip)}
          onAddHighlight={() => handleAddHighlight(activeTooltip)}
          setActiveTooltip={setActiveTooltip}
          activeTooltip={activeTooltip}
        />
      </div>

      {/* Privacy Modal */}
      {showPrivacyModal && (
        <AlertModal
          isOpen={showPrivacyModal}
          onClose={() => setShowPrivacyModal(false)}
          title="About"
          message={`Respaced is a tool designed to help individuals transform overwhelming conversations into actionable insights.

Who It’s For:
From remote workers and developers in standups to students, professionals, and digital nomads or anyone who has ever spaced out during long meetings.

What It Does:  
It's a real-time transcription and cognitive assistance platform that combines AI-driven insights, fallacy detection, and context-aware follow-up suggestions. It’s privacy-conscious by design, storing everything locally and giving users full control over their data.  

Why It Was Built:  
Recovering from distraction without losing the thread is the goal. Respaced won't help you master having perfect attention—it'll help you master a perfect recovery.`}
          confirmText="Got it"
          cancelText="Close"
          type="info"
          confirmButtonStyle="primary"
          onConfirm={() => setShowPrivacyModal(false)}
        />
      )}

      <style jsx global>{`
        .ProseMirror {
          min-height: 100%;
          padding-bottom: 2rem; /* Add padding at the bottom of the editor */
        }
        .ProseMirror {
          line-height: 1.5;
        }
        .ProseMirror p {
          margin: 1em 0;
        }
        .ProseMirror p.is-editor-empty:first-child::before {
          content: 'Start transcribing or copy-paste your transcript here.';
          float: left;
          color: #9ca3af;
          pointer-events: none;
          height: 0;
          font-style: italic;
        }
        /* Light Mode Animations */
        @keyframes highlight-appear {
          0% {
            opacity: 0;
            transform: scale(0.95);
            background-color: transparent;
          }
          50% {
            opacity: 1;
            transform: scale(1.02);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes highlight-delete {
          0% {
            transform: scale(1);
            background-color: inherit;
          }
          30% {
            background-color: rgba(239, 68, 68, 0.4);
            transform: scale(1.02);
          }
          100% {
            transform: scale(1);
            background-color: transparent;
          }
        }

        @keyframes highlight-complete {
          0% {
            background-color: inherit;
          }
          30% {
            background-color: rgba(34, 197, 94, 0.4);
          }
          100% {
            background-color: rgba(34, 197, 94, 0.1);
          }
        }

        .animate-highlight-appear {
          animation: highlight-appear 5s cubic-bezier(0.34, 1.56, 0.64, 1)
            forwards;
        }

        .animate-highlight-delete {
          animation: highlight-delete 8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }

        .animate-highlight-complete {
          animation: highlight-complete 5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
      `}</style>
    </div>
  );
};

export default TranscriptEditor;
