// AudioAnalysis.js
class AudioAnalysis {
    constructor(audioContext, options = {}) {
      this.audioContext = audioContext;
      this.options = {
        fftSize: 2048,
        smoothingTimeConstant: 0.8,
        minDecibels: -90,
        maxDecibels: -10,
        ...options
      };
      
      this.analyser = this.audioContext.createAnalyser();
      this.configureAnalyser();
      
      // Frequency bands for analysis (Hz)
      this.frequencyBands = {
        low: { min: 20, max: 300 },
        mid: { min: 300, max: 2000 },
        high: { min: 2000, max: 8000 }
      };
      
      // Analysis buffers
      this.frequencyData = new Float32Array(this.analyser.frequencyBinCount);
      this.timeData = new Float32Array(this.analyser.fftSize);
      
      // State tracking
      this.lastAnalysis = null;
      this.analysisHistory = [];
      this.MAX_HISTORY = 10;
    }
  
    configureAnalyser() {
      this.analyser.fftSize = this.options.fftSize;
      this.analyser.smoothingTimeConstant = this.options.smoothingTimeConstant;
      this.analyser.minDecibels = this.options.minDecibels;
      this.analyser.maxDecibels = this.options.maxDecibels;
    }
  
    connectSource(sourceNode) {
      sourceNode.connect(this.analyser);
      return this.analyser;
    }
  
    getFrequencyBinForHz(hz) {
      return Math.round((hz * this.analyser.fftSize) / this.audioContext.sampleRate);
    }
  
    calculateFrequencySpectrum() {
      this.analyser.getFloatFrequencyData(this.frequencyData);
      
      const spectrum = {
        low: 0,
        mid: 0,
        high: 0
      };
  
      Object.entries(this.frequencyBands).forEach(([band, { min, max }]) => {
        const startBin = this.getFrequencyBinForHz(min);
        const endBin = this.getFrequencyBinForHz(max);
        let sum = 0;
        let count = 0;
  
        for (let i = startBin; i <= endBin && i < this.frequencyData.length; i++) {
          // Convert from dB to linear scale
          const magnitude = Math.pow(10, this.frequencyData[i] / 20);
          sum += magnitude;
          count++;
        }
  
        spectrum[band] = count > 0 ? sum / count : 0;
      });
  
      return spectrum;
    }
  
    analyzeVolumePatterns() {
      this.analyser.getFloatTimeDomainData(this.timeData);
      
      let rms = 0;
      let peakLevel = 0;
      let crestFactor = 0;
  
      // Calculate RMS and peak level
      for (let i = 0; i < this.timeData.length; i++) {
        const amplitude = this.timeData[i];
        rms += amplitude * amplitude;
        peakLevel = Math.max(peakLevel, Math.abs(amplitude));
      }
  
      rms = Math.sqrt(rms / this.timeData.length);
      crestFactor = peakLevel / rms;
  
      return {
        rms,
        peakLevel,
        crestFactor
      };
    }
  
    detectEchoComponents() {
      // Analyze time domain for echo characteristics
      this.analyser.getFloatTimeDomainData(this.timeData);
      
      const correlationData = new Float32Array(this.timeData.length / 2);
      let maxCorrelation = 0;
      let dominantDelay = 0;
  
      // Autocorrelation to find periodic patterns
      for (let delay = 1; delay < correlationData.length; delay++) {
        let correlation = 0;
        
        for (let i = 0; i < correlationData.length; i++) {
          correlation += this.timeData[i] * this.timeData[i + delay];
        }
        
        correlationData[delay] = correlation;
        
        if (correlation > maxCorrelation) {
          maxCorrelation = correlation;
          dominantDelay = delay;
        }
      }
  
      // Convert delay to milliseconds
      const delayMs = (dominantDelay / this.audioContext.sampleRate) * 1000;
      
      return {
        maxCorrelation,
        dominantDelay: delayMs,
        hasEchoPattern: maxCorrelation > 0.7 && delayMs > 20 && delayMs < 200
      };
    }
  
    analyzeCharacteristics() {
      const spectrum = this.calculateFrequencySpectrum();
      const volume = this.analyzeVolumePatterns();
      const echo = this.detectEchoComponents();
  
      const analysis = {
        timestamp: Date.now(),
        spectrum,
        volume,
        echo,
        characteristics: this.deriveCharacteristics(spectrum, volume, echo)
      };
  
      this.updateHistory(analysis);
      this.lastAnalysis = analysis;
  
      return analysis;
    }
  
    deriveCharacteristics(spectrum, volume, echo) {
      // Characteristic patterns for different audio sources
      const characteristics = {
        isDirectSpeech: false,
        isLeakedAudio: false,
        confidence: 0
      };
  
      // Direct speech typically has:
      // 1. Higher mid-frequency content
      // 2. More consistent volume patterns
      // 3. Less echo components
      const directSpeechIndicators = [
        spectrum.mid > (spectrum.low + spectrum.high) / 2,
        volume.crestFactor < 4,
        !echo.hasEchoPattern
      ];
  
      // Leaked audio typically has:
      // 1. More compressed frequency range
      // 2. More variable volume patterns
      // 3. Noticeable echo components
      const leakedAudioIndicators = [
        spectrum.mid < (spectrum.low + spectrum.high) / 2,
        volume.crestFactor > 4,
        echo.hasEchoPattern
      ];
  
      const directSpeechScore = directSpeechIndicators.filter(Boolean).length / directSpeechIndicators.length;
      const leakedAudioScore = leakedAudioIndicators.filter(Boolean).length / leakedAudioIndicators.length;
  
      if (directSpeechScore > leakedAudioScore) {
        characteristics.isDirectSpeech = true;
        characteristics.confidence = directSpeechScore;
      } else {
        characteristics.isLeakedAudio = true;
        characteristics.confidence = leakedAudioScore;
      }
  
      return characteristics;
    }
  
    updateHistory(analysis) {
      this.analysisHistory.push(analysis);
      if (this.analysisHistory.length > this.MAX_HISTORY) {
        this.analysisHistory.shift();
      }
    }
  
    getConfidenceScore() {
      if (this.analysisHistory.length === 0) return 0;
  
      // Calculate confidence based on consistency of recent classifications
      const recentCharacteristics = this.analysisHistory.map(a => a.characteristics);
      const directSpeechCount = recentCharacteristics.filter(c => c.isDirectSpeech).length;
      const consistency = Math.abs(directSpeechCount / this.analysisHistory.length - 0.5) * 2;
      
      // Average confidence scores
      const avgConfidence = recentCharacteristics.reduce((sum, c) => sum + c.confidence, 0) 
        / recentCharacteristics.length;
  
      return consistency * avgConfidence;
    }
  }
  
  export default AudioAnalysis;