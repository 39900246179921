import { useAuth } from './authContext';
import { createCheckoutSession } from './stripeService';
import { useNavigate, useLocation } from 'react-router-dom';
import { XMarkIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { LoginModal } from './LoginModal';
import { useState } from 'react';

export const SubscribeModal = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleSubscribe = async () => {
    if (!user) {
      setShowLoginModal(true);
      return;
    }

    try {
      const sessionUrl = await createCheckoutSession();
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Subscription error:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        {/* Rest of the modal code remains the same until the footer */}
        <div className="px-6 py-4 bg-gray-50 dark:bg-gray-800/50 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2.5 rounded-lg text-sm font-medium border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSubscribe}
            className="px-4 py-2.5 rounded-lg text-sm font-medium bg-purple-600 hover:bg-purple-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 dark:focus:ring-offset-gray-900 transition-colors"
          >
            {user ? 'Subscribe Now' : 'Sign in to Subscribe'}
          </button>
        </div>
      </div>

      <LoginModal 
        isOpen={showLoginModal} 
        onClose={() => setShowLoginModal(false)} 
      />
    </>
  );
};