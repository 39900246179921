import { Mark, getMarkRange } from "@tiptap/core";

const SUGGESTION_TYPES = {
  QUESTION: "question",
  FALLACY: "fallacy",
  NUGGET: "nugget",
  MANUAL: "manual",
  NOTE: "note",
};

const SUGGESTION_COLORS = {
  [SUGGESTION_TYPES.QUESTION]: {
    default: "bg-blue-100/70 dark:bg-blue-900/50 hover:opacity-50 p-1 rounded",
  },
  [SUGGESTION_TYPES.FALLACY]: {
    default: "bg-rose-100/70 dark:bg-rose-900/50 hover:opacity-50 p-1 rounded",
  },
  [SUGGESTION_TYPES.NUGGET]: {
    default: "bg-purple-100/70 dark:bg-purple-900/50 hover:opacity-50 p-1 rounded",
  },
  [SUGGESTION_TYPES.MANUAL]: {
    default: "bg-yellow-100/70 dark:bg-yellow-900/50 hover:opacity-50 p-1 rounded",
  },
  [SUGGESTION_TYPES.NOTE]: {
    default: "bg-green-100/70 dark:bg-green-900/50 hover:opacity-50 p-1 rounded",
  },
  done: "bg-gray-100/70 dark:bg-neutral-800 hover:opacity-50 p-1 rounded",
  default: "hover:bg-gray-200 dark:hover:bg-neutral-800 p-1 rounded",
};

const Highlight = Mark.create({
  name: "highlight",

  priority: 1000,

  inclusive: false,

  spanning: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: "",
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-highlight-id"),
        renderHTML: (attributes) => ({
          "data-highlight-id": attributes.id,
        }),
      },
      paragraphId: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-paragraph-id"),
        renderHTML: (attributes) => ({
          "data-paragraph-id": attributes.paragraphId,
        }),
      },
      generated: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-generated") === "true",
        renderHTML: (attributes) => ({
          "data-generated": attributes.generated,
        }),
      },
      type: {
        default: "manual",
        parseHTML: (element) => element.getAttribute("data-highlight-type"),
        renderHTML: (attributes) => ({
          "data-highlight-type": attributes.type,
        }),
      },
      isHighlight: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-is-highlight") === "true",
        renderHTML: (attributes) => ({
          "data-is-highlight": attributes.isHighlight,
        }),
      },
      isOpen: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-is-open") === "true",
        renderHTML: (attributes) => ({
          "data-is-open": attributes.isOpen,
        }),
      },
      done: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-done") === "true",
        renderHTML: (attributes) => ({
          "data-done": attributes.done,
        }),
      },
      isNew: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-is-new") === "true",
        renderHTML: (attributes) => ({
          "data-is-new": attributes.isNew,
        }),
      },
      isDeleting: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-is-deleting") === "true",
        renderHTML: (attributes) => ({
          "data-is-deleting": attributes.isDeleting,
        }),
      },
      justCompleted: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-just-completed") === "true",
        renderHTML: (attributes) => ({
          "data-just-completed": attributes.justCompleted,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-highlight]",
        getAttrs: (element) => ({
          id: element.getAttribute("data-highlight-id"),
          paragraphId: element.getAttribute("data-paragraph-id"),
          generated: element.getAttribute("data-generated") === "true",
          type: element.getAttribute("data-highlight-type") || "manual",
          isHighlight: element.getAttribute("data-is-highlight") === "true",
          isOpen: element.getAttribute("data-is-open") === "true",
          done: element.getAttribute("data-done") === "true",
          isNew: element.getAttribute("data-is-new") === "true",
          isDeleting: element.getAttribute("data-is-deleting") === "true",
          justCompleted: element.getAttribute("data-just-completed") === "true",
        }),
      },
    ];
  },

  renderHTML({ mark, HTMLAttributes }) {
    let highlightClass = SUGGESTION_COLORS.default;
    let animationClass = "";

    if (mark.attrs.isNew) {
      animationClass = "animate-highlight-appear";
    } else if (mark.attrs.isDeleting) {
      animationClass = "animate-highlight-delete";
    } else if (mark.attrs.justCompleted) {
      animationClass = "animate-highlight-complete";
    }

    if (mark.attrs.generated || mark.attrs.isHighlight) {
      if (mark.attrs.done) {
        highlightClass = SUGGESTION_COLORS.done;
      } else {
        highlightClass =
          SUGGESTION_COLORS[mark.attrs.type]?.default ||
          SUGGESTION_COLORS[SUGGESTION_TYPES.MANUAL].default;
      }
    }

    return [
      "span",
      {
        ...HTMLAttributes,
        "data-highlight": "true",
        "data-highlight-id": mark.attrs.id,
        "data-paragraph-id": mark.attrs.paragraphId,
        "data-generated": mark.attrs.generated,
        "data-highlight-type": mark.attrs.type,
        "data-is-highlight": mark.attrs.isHighlight,
        "data-is-open": mark.attrs.isOpen,
        "data-done": mark.attrs.done,
        "data-is-new": mark.attrs.isNew,
        "data-is-deleting": mark.attrs.isDeleting,
        "data-just-completed": mark.attrs.justCompleted,
        class: `${highlightClass} ${animationClass} cursor-text hover:cursor-pointer mix-blend-multiply dark:mix-blend-lighten relative group highlight-content`,
        style: "position: relative; caret-color: orange;",
      },
      0,
    ];
  },

  addCommands() {
    return {
      setHighlight:
        (attributes) =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          const { empty, ranges } = selection;

          if (empty) return false;

          if (dispatch) {
            ranges.forEach((range) => {
              let paragraphId = null;
              state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node, pos) => {
                if (node.type.name === "paragraph") {
                  paragraphId = node.attrs["data-paragraph-id"];
                  return false;
                }
              });

              if (paragraphId) {
                tr.addMark(
                  range.$from.pos,
                  range.$to.pos,
                  this.type.create({ ...attributes, paragraphId })
                );
              }
            });

            dispatch(tr);
          }

          return true;
        },

      toggleHighlight:
        (attributes) =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          const { empty, ranges } = selection;

          if (empty) return false;

          if (dispatch) {
            ranges.forEach((range) => {
              const mark = state.doc.rangeHasMark(range.$from.pos, range.$to.pos, this.type);

              if (mark) {
                tr.removeMark(range.$from.pos, range.$to.pos, this.type);
              } else {
                let paragraphId = null;
                state.doc.nodesBetween(range.$from.pos, range.$to.pos, (node, pos) => {
                  if (node.type.name === "paragraph") {
                    paragraphId = node.attrs["data-paragraph-id"];
                    return false;
                  }
                });

                if (paragraphId) {
                  tr.addMark(
                    range.$from.pos,
                    range.$to.pos,
                    this.type.create({ ...attributes, paragraphId })
                  );
                }
              }
            });

            dispatch(tr);
          }

          return true;
        },

      unsetHighlight:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          const { empty, ranges } = selection;

          if (empty) return false;

          if (dispatch) {
            ranges.forEach((range) => {
              tr.removeMark(range.$from.pos, range.$to.pos, this.type);
            });

            dispatch(tr);
          }

          return true;
        },
    };
  },

  extendTransactions() {
    return [
      (tr, state) => {
        if (!tr.docChanged) return tr;

        let newTr = tr;

        const highlightsByParagraph = new Map();

        state.doc.descendants((node, pos) => {
          if (node.type.name === "paragraph") {
            const paragraphId = node.attrs["data-paragraph-id"];
            if (!paragraphId) return;

            node.descendants((textNode, textPos) => {
              if (textNode.marks) {
                const highlightMark = textNode.marks.find(
                  (m) =>
                    m.type.name === "highlight" &&
                    m.attrs.paragraphId === paragraphId
                );
                if (highlightMark) {
                  highlightsByParagraph.set(paragraphId, {
                    mark: highlightMark,
                    pos: pos + textPos,
                    size: textNode.nodeSize,
                  });
                }
              }
            });
          }
        });

        tr.doc.descendants((node, pos) => {
          if (node.type.name === "paragraph") {
            const paragraphId = node.attrs["data-paragraph-id"];
            if (!paragraphId) return;

            const highlight = highlightsByParagraph.get(paragraphId);
            if (highlight) {
              let hasHighlight = false;
              node.descendants((textNode, textPos) => {
                if (textNode.marks) {
                  const mark = textNode.marks.find(
                    (m) =>
                      m.type.name === "highlight" &&
                      m.attrs.paragraphId === paragraphId
                  );
                  if (mark) hasHighlight = true;
                }
              });

              if (!hasHighlight) {
                const from = pos + 1;
                const to = pos + node.nodeSize - 1;
                newTr = newTr
                  .removeMark(from, to, this.type)
                  .addMark(from, to, highlight.mark);
              }
            }
          }
        });

        return newTr;
      },
    ];
  },
});

export default Highlight;
