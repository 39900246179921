import React, { useEffect, useCallback, useRef, useState } from "react";
import TranscriptEditor from "./TranscriptEditor";
import TranscriptionService from "./TranscriptionService";
import { v4 as uuidv4 } from "uuid";
import DualTranscriptionManager from "./DualTranscriptionManager";

const TranscriptManager = ({
  currentTopicIndex,
  transcriptionByTopic,
  setTranscriptionByTopic,
  suggestionsByTopic,
  setSuggestionsByTopic,
  onSuggestionUpdate,
  handleTranscript,
  setupCompleted,
  cooldown,
  hasGeneratedInitially,
  handleTranscriptionInit,
  setActiveTooltip,
  activeTooltip,
  handleGenerateClick,
  handleDeleteSuggestion,
  handleCompleteSuggestion,
  micServiceRef,
  tabServiceRef,
  lastProcessedRef,
  micActive,
  setMicActive,
  tabActive,
  setTabActive,
  isAnySourceActive,
  stopBothSources,
  initiateStartProcess,
  isStarting,
  showEarphonesModal,
  setShowEarphonesModal,
  setIsStarting,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  topics,
  showPrivacyModal,
  setShowPrivacyModal,
}) => {

  // In TranscriptManager.js
  const handleTranscriptionUpdate = (paragraphs, from, to) => {
    // Get the current state first
    const currentSuggestions = suggestionsByTopic[currentTopicIndex] || {
      questions: [],
      fallacies: [],
      nuggets: [],
      manual: [],
      notes: [],
    };

    // Store manual highlights before any updates
    const existingManualHighlights = [...(currentSuggestions.manual || [])];
    console.log("Current manual highlights:", existingManualHighlights);

    // Create updated transcription
    const updatedTranscription = paragraphs.map((paragraph, idx) => {
      const previousLine = transcriptionByTopic[currentTopicIndex]?.[idx] || {};
      const text = paragraph.textContent;

      // Check if this line has a manual highlight
      const hasManualHighlight = existingManualHighlights.some(
        (highlight) => highlight["Quoted Sentence ID"] === previousLine.id
      );

      // Extract speaker from text label and keep it uppercase
      const speakerMatch = text.match(/^\s*\[(TAB AUDIO|MICROPHONE)\]:/i);
      const speaker = speakerMatch ? speakerMatch[1].toUpperCase() : previousLine.speaker;

      return {
        id: previousLine.id || uuidv4(),
        text,
        generated: previousLine.generated || false,
        type: hasManualHighlight ? "manual" : previousLine.type || null,
        isHighlight: hasManualHighlight || previousLine.isHighlight || false,
        ...(previousLine.speaker && { speaker: previousLine.speaker }),
        ...(previousLine.source && { source: previousLine.source }),
      };
    });

    // Create updated suggestions while preserving manual highlights
    const updatedSuggestions = {
      questions: currentSuggestions.questions.map((suggestion) => {
        const matchingLine = updatedTranscription.find(
          (line) => line.text === suggestion["Quoted Sentence"]
        );
        return matchingLine
          ? { ...suggestion, "Quoted Sentence ID": matchingLine.id }
          : suggestion;
      }),
      fallacies: currentSuggestions.fallacies.map((suggestion) => {
        const matchingLine = updatedTranscription.find(
          (line) => line.text === suggestion["Quoted Sentence"]
        );
        return matchingLine
          ? { ...suggestion, "Quoted Sentence ID": matchingLine.id }
          : suggestion;
      }),
      nuggets: currentSuggestions.nuggets.map((suggestion) => {
        const matchingLine = updatedTranscription.find(
          (line) => line.text === suggestion["Quoted Sentence"]
        );
        return matchingLine
          ? { ...suggestion, "Quoted Sentence ID": matchingLine.id }
          : suggestion;
      }),
      notes: currentSuggestions.notes.map((suggestion) => {
        const matchingLine = updatedTranscription.find(
          (line) => line.text === suggestion["Quoted Sentence"]
        );
        return matchingLine
          ? { ...suggestion, "Quoted Sentence ID": matchingLine.id }
          : suggestion;
      }),
      manual: existingManualHighlights.map((highlight) => {
        const matchingLine = updatedTranscription.find(
          (line) => line.text === highlight["Quoted Sentence"]
        );
        return matchingLine
          ? { ...highlight, "Quoted Sentence ID": matchingLine.id }
          : highlight;
      }),
    };

    // Batch our updates to avoid race conditions
    const batchUpdate = () => {
      // First update transcription
      const newTranscriptionState = {
        ...transcriptionByTopic,
        [currentTopicIndex]: updatedTranscription,
      };
      setTranscriptionByTopic(newTranscriptionState);
      localStorage.setItem(
        "transcriptionByTopic",
        JSON.stringify(newTranscriptionState)
      );

      // Then update suggestions
      const newSuggestionsState = {
        ...suggestionsByTopic,
        [currentTopicIndex]: updatedSuggestions,
      };
      setSuggestionsByTopic(newSuggestionsState);
      localStorage.setItem(
        "suggestionsByTopic",
        JSON.stringify(newSuggestionsState)
      );

      console.log("After update - manual highlights:", updatedSuggestions.manual);
    };

    // Execute our batch update
    batchUpdate();

    // Handle scroll position
    const editorElement = document.querySelector(".ProseMirror");
    if (editorElement) {
      const scrollTop = editorElement.scrollTop;
      requestAnimationFrame(() => {
        editorElement.scrollTop = scrollTop;
      });
    }
  };

  useEffect(() => {
    const verifyState = () => {
      const storedSuggestions = localStorage.getItem("suggestionsByTopic");
      if (storedSuggestions) {
        try {
          const parsed = JSON.parse(storedSuggestions);
          const currentState = suggestionsByTopic;

          // Check if manual highlights match
          Object.keys(parsed).forEach((topicIndex) => {
            const storedManualHighlights = parsed[topicIndex].manual || [];
            const currentManualHighlights = currentState[topicIndex]?.manual || [];

            if (
              JSON.stringify(storedManualHighlights) !==
              JSON.stringify(currentManualHighlights)
            ) {
              console.log(
                "Manual highlights mismatch detected - restoring from localStorage"
              );
              setSuggestionsByTopic(parsed);
            }
          });
        } catch (error) {
          console.error("Error verifying state:", error);
        }
      }
    };

    verifyState();
  }, []); // Run once on mount

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "suggestionsByTopic") {
        const newValue = JSON.parse(e.newValue);
        setSuggestionsByTopic(newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return (
    <>
      <TranscriptEditor
        currentTopicIndex={currentTopicIndex}
        transcriptionByTopic={transcriptionByTopic}
        suggestionsByTopic={suggestionsByTopic}
        setSuggestionsByTopic={setSuggestionsByTopic}
        handleTranscriptionUpdate={handleTranscriptionUpdate}
        onSuggestionUpdate={onSuggestionUpdate}
        handleCompleteSuggestion={handleCompleteSuggestion}
        handleDeleteSuggestion={handleDeleteSuggestion}
        setActiveTooltip={setActiveTooltip}
        activeTooltip={activeTooltip}
        setTranscriptionByTopic={setTranscriptionByTopic}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
        topics={topics}
        showPrivacyModal={showPrivacyModal}
        setShowPrivacyModal={setShowPrivacyModal}
      />

      <DualTranscriptionManager
        setupCompleted={setupCompleted}
        currentTopicIndex={currentTopicIndex}
        onTranscript={handleTranscript}
        cooldown={cooldown}
        hasGeneratedInitially={hasGeneratedInitially}
        onInit={handleTranscriptionInit}
        onStateChange={(isListening) => {
          console.log("Transcription services state changed:", isListening);
        }}
        handleGenerateClick={handleGenerateClick}
        isAnySourceActive={isAnySourceActive}
        stopBothSources={stopBothSources}
        initiateStartProcess={initiateStartProcess}
        isStarting={isStarting}
        micActive={micActive}
        setMicActive={setMicActive}
        tabActive={tabActive}
        setTabActive={setTabActive}
        setIsStarting={setIsStarting}
        showEarphonesModal={showEarphonesModal}
        setShowEarphonesModal={setShowEarphonesModal}
        micServiceRef={micServiceRef}
        tabServiceRef={tabServiceRef}
        lastProcessedRef={lastProcessedRef}
      />
    </>
  );
};

export default TranscriptManager;


