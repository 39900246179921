import React, { useState, useCallback, useRef, useEffect } from "react";
import "./index.css";
import Navbar from "./Navbar";
import TranscriptManager from "./TranscriptManager";
import { generateSuggestions } from "./suggestionService";
import useAppEffects from "./useAppEffets";
import { minimumLinesNeeded, numberOfLinesToSend } from "./constants";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique id generation
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import AlertModal from "./AlertModal";
import { AuthProvider, useAuth } from "./authContext";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SubscribeModal } from "./SubscribeModal";
import { SubscriptionCheck } from "./SubscriptionCheck";
import { SuccessPage } from "./SuccessPage";
import { LoginModal } from "./LoginModal";
import { ThemeProvider } from "./ThemeProvider";
import { useSharedGenerations } from "./useSharedGenerations";

const AppContent = () => {
  const { isSubscribed } = useAuth();
  const initializeSuggestions = () => {
    const defaultState = {
      0: {
        questions: [],
        fallacies: [],
        nuggets: [],
        notes: [],
        manual: [],
      },
    };

    try {
      // Immediately ensure default state exists in localStorage
      const savedSuggestions = localStorage.getItem("suggestionsByTopic");
      if (!savedSuggestions || savedSuggestions === "null") {
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(defaultState)
        );
        return defaultState;
      }

      let parsed;
      try {
        parsed = JSON.parse(savedSuggestions);
      } catch (e) {
        // If parsing fails, reset to default
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(defaultState)
        );
        return defaultState;
      }

      // More aggressive validation
      if (
        !parsed ||
        typeof parsed !== "object" ||
        !parsed[0] ||
        Object.keys(parsed).length === 0
      ) {
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(defaultState)
        );
        return defaultState;
      }

      // Initialize state with existing data
      const initializedState = Object.entries(parsed).reduce(
        (acc, [topicIndex, topicData]) => {
          const validTopicIndex = Number(topicIndex);
          if (isNaN(validTopicIndex)) return acc;

          acc[validTopicIndex] = {
            questions: Array.isArray(topicData?.questions)
              ? [...topicData.questions]
              : [],
            fallacies: Array.isArray(topicData?.fallacies)
              ? [...topicData.fallacies]
              : [],
            nuggets: Array.isArray(topicData?.nuggets)
              ? [...topicData.nuggets]
              : [],
            notes: Array.isArray(topicData?.notes)
              ? [...topicData.notes]
              : [],
            manual: Array.isArray(topicData?.manual)
              ? [...topicData.manual]
              : [],
          };

          return acc;
        },
        {}
      );

      // Always ensure topic 0 exists
      if (!initializedState[0]) {
        initializedState[0] = defaultState[0];
      }

      // Update localStorage with cleaned data
      localStorage.setItem(
        "suggestionsByTopic",
        JSON.stringify(initializedState)
      );
      return initializedState;
    } catch (error) {
      console.error("Error initializing suggestions:", error);
      // On any error, reset to default state
      localStorage.setItem("suggestionsByTopic", JSON.stringify(defaultState));
      return defaultState;
    }
  };
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
  const [editTopic, setEditTopic] = useState("");
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(5);
  const intervalRef = useRef(null);
  const [error, setError] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [availableLineCount, setAvailableLineCount] = useState(0);
  const cooldownDuration = 5;

  const OPENAI_CONFIG = {
    API_URL: "https://api.openai.com/v1/chat/completions",
    MODELS: {
      GPT_3_5_TURBO: "gpt-3.5-turbo",
    },
    API_KEY: localStorage.getItem("openai_api_key") || "",
  };

  const [mainTopic, setMainTopic] = useState(
    () => localStorage.getItem("mainTopic") || ""
  );

  const [topics, setTopics] = useState(() => {
    const savedTopics = localStorage.getItem("topics");
    const initialTopics = savedTopics ? JSON.parse(savedTopics) : [];
    return initialTopics.length > 0 ? initialTopics : ["Notes"];
  });
  const [transcriptionByTopic, setTranscriptionByTopic] = useState(() => {
    const savedTranscription =
      JSON.parse(localStorage.getItem("transcriptionByTopic")) || {};
    return savedTranscription;
  });
  const [suggestionsByTopic, setSuggestionsByTopic] = useState(() => {
    try {
      const saved = localStorage.getItem("suggestionsByTopic");
      if (!saved) return initializeSuggestions();
      const parsed = JSON.parse(saved);
      return parsed || initializeSuggestions();
    } catch {
      return initializeSuggestions();
    }
  });

  const isStateInitialized = suggestionsByTopic && suggestionsByTopic[0];

  const [success, setSuccess] = useState("");

  // Add this useEffect to auto-dismiss success messages
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess("");
      }, 5000); // Hide after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [success]);

  useEffect(() => {
    // Deep clone to avoid reference issues when logging
    const stateToCheck = JSON.parse(JSON.stringify(suggestionsByTopic));
    console.log("Verifying suggestionsByTopic state:", {
      fullState: stateToCheck,
      topics: Object.keys(stateToCheck),
      manualHighlights: Object.keys(stateToCheck).reduce((acc, topicIndex) => {
        acc[topicIndex] = stateToCheck[topicIndex].manual?.length || 0;
        return acc;
      }, {}),
    });
  }, []);

  // Add this function to App.js
  const migrateLocalStorage = () => {
    try {
      // Add a version check
      const storageVersion = localStorage.getItem("storageVersion");

      if (!storageVersion) {
        // Clear all potentially problematic storage keys
        localStorage.removeItem("suggestionsByTopic");
        localStorage.removeItem("transcriptionByTopic");

        // Set initial state
        const defaultState = {
          0: {
            questions: [],
            fallacies: [],
            nuggets: [],
            notes: [],
            manual: [],
          }
        };

        localStorage.setItem("suggestionsByTopic", JSON.stringify(defaultState));
        localStorage.setItem("transcriptionByTopic", JSON.stringify({ 0: [] }));

        // Set the current version
        localStorage.setItem("storageVersion", "1.0");
      }

      // Add future migrations here with version checks
      // if (storageVersion === "1.0") { migrate to 1.1 }

    } catch (error) {
      console.error("Migration failed:", error);
      // On any error, reset to default state
      localStorage.clear();
    }
  };

  useEffect(() => {
    migrateLocalStorage();
  }, []); // Run once on mount

  const [generateQuestions, setGenerateQuestions] = useState(() =>
    JSON.parse(localStorage.getItem("generateQuestions") || "false")
  );
  const [generateFallacies, setGenerateFallacies] = useState(() =>
    JSON.parse(localStorage.getItem("generateFallacies") || "false")
  );
  const [generateNuggets, setGenerateNuggets] = useState(() =>
    JSON.parse(localStorage.getItem("generateNuggets") || "false")
  );
  const [contextDocument, setContextDocument] = useState(
    () => localStorage.getItem("contextDocument") || ""
  );
  const [generateNotes, setGenerateNotes] = useState(() =>
    JSON.parse(localStorage.getItem("generateNotes") || "true")
  );

  const checkSetupCompletion = useCallback(() => {
    const isApiKeyValid = OPENAI_CONFIG.API_KEY.trim() !== ""; // Check API key validity
    const isContextFilled = contextDocument.trim() !== "";
    const isSubtopicFilled = topics.some((topic) => topic.trim() !== "");
    const isMainTopicFilled = mainTopic.trim() !== ""; // Check main topic

    setSetupCompleted(
      isApiKeyValid && isContextFilled && isSubtopicFilled && isMainTopicFilled
    );
  }, [contextDocument, topics, mainTopic, OPENAI_CONFIG.API_KEY, isCollapsed]);

  const [setupCompleted, setSetupCompleted] = useState(true); // New state for setup completion
  const [isValidApiKey, setIsValidApiKey] = useState(false); // Add state for API key validity

  const { availableLineCountRef, currentTopicIndexRef } = useAppEffects({
    availableLineCount,
    setAvailableLineCount,
    topics,
    setTopics,
    generateQuestions,
    generateFallacies,
    generateNuggets,
    generateNotes,
    mainTopic,
    transcriptionByTopic,
    suggestionsByTopic,
    setSuggestionsByTopic,
    currentTopicIndex,
    cooldown,
    cooldownDuration,
    setCooldown,
    setCooldownTime,
    error,
    setError,
    contextDocument,
    setIsValidApiKey,
    isValidApiKey,
    checkSetupCompletion, // Pass checkSetupCompletion
    OPENAI_CONFIG,
  });

  const hasGeneratedInitially = useRef(false);

  const progressPercentage = (currentTopicIndex / (topics.length - 1)) * 100;

  const calculateAvailableLines = (transcription) => {
    return transcription.filter(
      (entry) =>
        entry &&
        !entry.generated &&
        !entry.done &&
        entry.text &&
        entry.text.trim().length > 0
    ).length;
  };

  const handleApiKeyChange = (key, isValid) => {
    OPENAI_CONFIG.API_KEY = key; // Update the API key in OPENAI_CONFIG
    setIsValidApiKey(isValid);

    checkSetupCompletion(); // Call checkSetupCompletion to update setupCompleted
  };

  const handleContextDocumentChange = (e) => {
    setContextDocument(e.target.value);
    localStorage.setItem("contextDocument", e.target.value); // Save to localStorage
  };

  const { remainingGenerations, generationsError, checkAndIncrementCounter } = useSharedGenerations(OPENAI_CONFIG.API_KEY.trim() !== "");

  const handleGenerateClick = async () => {
    console.log("Generating suggestion...");
    try {
      const hasOwnApiKey = OPENAI_CONFIG.API_KEY.trim() !== "";

      // First just check if we can generate
      if (!hasOwnApiKey) {
        const canGenerate = await checkAndIncrementCounter(false);
        if (!canGenerate) {
          setError(generationsError || "Daily generation limit reached. Please try again tomorrow or add your own API key.");
          return;
        }
      }

      // Temporarily set the API key to shared key if using shared generations
      const originalApiKey = OPENAI_CONFIG.API_KEY;
      if (!hasOwnApiKey && process.env.REACT_APP_OPENAI_API_KEY) {
        OPENAI_CONFIG.API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
      }

      try {
        const result = await generateSuggestions({
          currentTopicIndex,
          transcriptionByTopic,
          mainTopic,
          topics,
          contextDocument,
          generateQuestions,
          generateFallacies,
          generateNuggets,
          generateNotes,
          minimumLinesNeeded,
          numberOfLinesToSend,
          isSubscribed,
        });

        // If using shared key, update the counter based on success
        if (!hasOwnApiKey) {
          await checkAndIncrementCounter(result.success !== false);
        }

        if (result.success === false) {
          setSuccess(result.message);
        } else {
          if (
            result.questions?.length > 0 ||
            result.fallacies?.length > 0 ||
            result.nuggets?.length > 0 ||
            result.notes?.length > 0
          ) {
            setSuccess("New suggestion generated!");
            updateTranscriptionWithSuggestions(result);
          }
        }

        setCooldown(true);
        setCooldownTime(cooldownDuration);
        localStorage.setItem("cooldownStartTime", Date.now().toString());
      } finally {
        // Restore original API key
        if (originalApiKey) {
          OPENAI_CONFIG.API_KEY = originalApiKey;
        } else {
          localStorage.removeItem('openai_api_key');
        }
      }
    } catch (error) {
      console.error("Generation failed:", error);
      setError(error.message);
    }
  };


  // Helper function to update transcription and suggestions
  const updateTranscriptionWithSuggestions = (newSuggestions) => {
    setSuggestionsByTopic((prevSuggestions) => {
      const currentTopicSuggestions = prevSuggestions[currentTopicIndex] || {
        questions: [],
        fallacies: [],
        nuggets: [],
        notes: [],
        manual: [], // Preserve manual highlights array
      };

      const updatedState = {
        ...prevSuggestions,
        [currentTopicIndex]: {
          questions: [
            ...(currentTopicSuggestions.questions || []),
            ...(newSuggestions.questions || []),
          ],
          fallacies: [
            ...(currentTopicSuggestions.fallacies || []),
            ...(newSuggestions.fallacies || []),
          ],
          nuggets: [
            ...(currentTopicSuggestions.nuggets || []),
            ...(newSuggestions.nuggets || []),
          ],
          notes: [...(currentTopicSuggestions.notes || []), ...(newSuggestions.notes || [])],
          manual: [...(currentTopicSuggestions.manual || [])], // Preserve existing manual highlights
        },
      };

      // Save to localStorage immediately
      localStorage.setItem("suggestionsByTopic", JSON.stringify(updatedState));
      return updatedState;
    });

    const updatedTranscription = { ...transcriptionByTopic };
    Object.keys(newSuggestions).forEach((type) => {
      newSuggestions[type].forEach((suggestion) => {
        const sentenceID = suggestion["Quoted Sentence ID"];
        const topicTranscript = updatedTranscription[currentTopicIndex];
        const entryIndex = topicTranscript.findIndex(
          (entry) => entry.id === sentenceID
        );

        if (entryIndex !== -1) {
          topicTranscript[entryIndex] = {
            ...topicTranscript[entryIndex],
            generated: true,
            highlighted: true,
            type: suggestion.type,
          };
        }
      });
    });

    // Save to localStorage immediately
    localStorage.setItem(
      "transcriptionByTopic",
      JSON.stringify(updatedTranscription)
    );
    setTranscriptionByTopic(updatedTranscription);
  };

  // Handlers for Topics
  const handleAddTopic = () => {
    const newTopic = editTopic.trim() || "Part 2";

    // Update topics array
    setTopics((prevTopics) => {
      const updatedTopics = [...prevTopics, newTopic];
      localStorage.setItem("topics", JSON.stringify(updatedTopics));
      return updatedTopics;
    });

    // Initialize suggestion structure for new topic
    setSuggestionsByTopic((prevSuggestions) => {
      const newTopicIndex = Object.keys(prevSuggestions).length;
      const updatedSuggestions = {
        ...prevSuggestions,
        [newTopicIndex]: {
          questions: [],
          fallacies: [],
          nuggets: [],
          notes: [],
          manual: [], // Explicitly initialize manual highlights array
        },
      };
      localStorage.setItem(
        "suggestionsByTopic",
        JSON.stringify(updatedSuggestions)
      );
      return updatedSuggestions;
    });

    // Initialize transcription structure for new topic
    setTranscriptionByTopic((prevTranscription) => {
      const newTopicIndex = Object.keys(prevTranscription).length;
      const updatedTranscription = {
        ...prevTranscription,
        [newTopicIndex]: [],
      };
      localStorage.setItem(
        "transcriptionByTopic",
        JSON.stringify(updatedTranscription)
      );
      return updatedTranscription;
    });

    setEditTopic(""); // Reset the edit input
  };
  const handleTopicChange = (e, index) => {
    const newTopic = e.target.value;
    setTopics((prevTopics) =>
      prevTopics.map((topic, i) => (i === index ? newTopic : topic))
    );
  };
  // Updated handleRemoveTopic function
  const handleRemoveTopic = (index) => {
    // Check if there's more than one topic
    if (topics.length <= 1) {
      setError("At least one subtopic is required.");
      return; // Exit the function early to prevent deletion
    }

    setError(""); // Clear any previous error message

    const newTopics = topics.filter((_, i) => i !== index);

    // Remove transcription for the deleted topic
    const { [index]: removedTranscription, ...remainingTranscriptions } =
      transcriptionByTopic;
    const updatedTranscriptionByTopic = Object.keys(
      remainingTranscriptions
    ).reduce((acc, key) => {
      const numericKey = parseInt(key);
      if (numericKey > index) {
        acc[numericKey - 1] = remainingTranscriptions[key];
      } else {
        acc[numericKey] = remainingTranscriptions[key];
      }
      return acc;
    }, {});

    // Remove suggestions for the deleted topic
    const { [index]: removedSuggestions, ...remainingSuggestions } =
      suggestionsByTopic;
    const updatedSuggestionsByTopic = Object.keys(remainingSuggestions).reduce(
      (acc, key) => {
        const numericKey = parseInt(key);
        if (numericKey > index) {
          acc[numericKey - 1] = remainingSuggestions[key];
        } else {
          acc[numericKey] = remainingSuggestions[key];
        }
        return acc;
      },
      {}
    );

    setTopics(newTopics);
    setTranscriptionByTopic(updatedTranscriptionByTopic);
    setSuggestionsByTopic(updatedSuggestionsByTopic);

    // Update localStorage to persist changes
    localStorage.setItem(
      "transcriptionByTopic",
      JSON.stringify(updatedTranscriptionByTopic)
    );
    localStorage.setItem(
      "suggestionsByTopic",
      JSON.stringify(updatedSuggestionsByTopic)
    );
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    // Reorder topics
    const newTopics = Array.from(topics);
    const draggedItem = newTopics.splice(draggedIndex, 1)[0];
    newTopics.splice(index, 0, draggedItem);

    // Reorder transcriptionByTopic
    const newTranscriptionByTopic = { ...transcriptionByTopic };
    const draggedTranscription = newTranscriptionByTopic[draggedIndex];
    const updatedTranscriptionByTopic = Object.keys(newTranscriptionByTopic)
      .filter((key) => key != draggedIndex)
      .reduce((acc, key, idx) => {
        if (idx >= index) {
          acc[idx + 1] = newTranscriptionByTopic[key];
        } else {
          acc[idx] = newTranscriptionByTopic[key];
        }
        return acc;
      }, {});
    updatedTranscriptionByTopic[index] = draggedTranscription;

    // Reorder suggestionsByTopic
    const newSuggestionsByTopic = { ...suggestionsByTopic };
    const draggedSuggestions = newSuggestionsByTopic[draggedIndex];
    const updatedSuggestionsByTopic = Object.keys(newSuggestionsByTopic)
      .filter((key) => key != draggedIndex)
      .reduce((acc, key, idx) => {
        if (idx >= index) acc[idx + 1] = newSuggestionsByTopic[key];
        else acc[idx] = newSuggestionsByTopic[key];
        return acc;
      }, {});
    updatedSuggestionsByTopic[index] = draggedSuggestions;

    // Update state
    setTopics(newTopics);
    setTranscriptionByTopic(updatedTranscriptionByTopic);
    setSuggestionsByTopic(updatedSuggestionsByTopic);
    setDraggedIndex(null);
  };

  const handleCompleteSuggestion = (suggestionId) => {
    // Store current scroll position
    const editorElement = document.querySelector(".ProseMirror");
    const scrollTop = editorElement?.scrollTop || 0;

    setSuggestionsByTopic((prevSuggestionsByTopic) => {
      let topicIndex, suggestionType;
      for (const [index, topicSuggestions] of Object.entries(
        prevSuggestionsByTopic
      )) {
        for (const [type, suggestions] of Object.entries(topicSuggestions)) {
          const match = suggestions.find(
            (suggestion) => suggestion.id === suggestionId
          );
          if (match) {
            topicIndex = index;
            suggestionType = type;
            break;
          }
        }
        if (topicIndex) break;
      }

      if (!topicIndex || !suggestionType) return prevSuggestionsByTopic;

      const updatedState = {
        ...prevSuggestionsByTopic,
        [topicIndex]: {
          ...prevSuggestionsByTopic[topicIndex],
          [suggestionType]: prevSuggestionsByTopic[topicIndex][
            suggestionType
          ].map((suggestion) =>
            suggestion.id === suggestionId
              ? { ...suggestion, done: !suggestion.done }
              : suggestion
          ),
        },
      };

      // Update localStorage asynchronously
      Promise.resolve().then(() => {
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(updatedState)
        );
      });

      return updatedState;
    });

    // Restore scroll position
    requestAnimationFrame(() => {
      if (editorElement) {
        editorElement.scrollTop = scrollTop;
      }
    });
  };

  const handleDeleteSuggestion = (suggestionId) => {
    // Store current scroll position
    const editorElement = document.querySelector(".ProseMirror");
    const scrollTop = editorElement?.scrollTop || 0;

    setSuggestionsByTopic((prevSuggestionsByTopic) => {
      let topicIndex, suggestionType;
      let quotedSentenceId = null;
      for (const [index, topicSuggestions] of Object.entries(
        prevSuggestionsByTopic
      )) {
        for (const [type, suggestions] of Object.entries(topicSuggestions)) {
          const match = suggestions.find(
            (suggestion) => suggestion.id === suggestionId
          );
          if (match) {
            topicIndex = index;
            suggestionType = type;
            quotedSentenceId = match["Quoted Sentence ID"];
            break;
          }
        }
        if (topicIndex) break;
      }

      if (!topicIndex || !suggestionType) return prevSuggestionsByTopic;

      const updatedState = {
        ...prevSuggestionsByTopic,
        [topicIndex]: {
          ...prevSuggestionsByTopic[topicIndex],
          [suggestionType]: prevSuggestionsByTopic[topicIndex][
            suggestionType
          ].filter((suggestion) => suggestion.id !== suggestionId),
        },
      };

      // Update localStorage asynchronously
      Promise.resolve().then(() => {
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(updatedState)
        );
      });

      setTranscriptionByTopic((prev) => {
        const updatedTranscription = {
          ...prev,
          [topicIndex]: prev[topicIndex].map((entry) =>
            entry.id === quotedSentenceId
              ? { ...entry, generated: false, type: null }
              : entry
          ),
        };

        const newAvailableLines = calculateAvailableLines(
          updatedTranscription[topicIndex]
        );
        availableLineCountRef.current = newAvailableLines;

        // Update localStorage asynchronously
        Promise.resolve().then(() => {
          localStorage.setItem(
            "transcriptionByTopic",
            JSON.stringify(updatedTranscription)
          );
        });

        return updatedTranscription;
      });

      return updatedState;
    });

    // Restore scroll position
    requestAnimationFrame(() => {
      if (editorElement) {
        editorElement.scrollTop = scrollTop;
      }
    });
  };

  const handleTranscript = useCallback(
    (text, { metadata } = {}) => {
      const processedText = ensureSentenceEndsWithPunctuation(text);
      const newEntry = {
        id: uuidv4(),
        text: processedText,
        generated: false,
        // Add speaker metadata
        speaker: metadata?.speaker || "unknown",
        source: metadata?.source || "unknown",
      };

      setTranscriptionByTopic((prev) => {
        const updatedTranscription = {
          ...prev,
          [currentTopicIndex]: [...(prev[currentTopicIndex] || []), newEntry],
        };

        // Update available lines immediately
        const newAvailableLines = calculateAvailableLines(
          updatedTranscription[currentTopicIndex]
        );
        setAvailableLineCount(newAvailableLines);

        // Update localStorage
        localStorage.setItem(
          "transcriptionByTopic",
          JSON.stringify(updatedTranscription)
        );

        return updatedTranscription;
      });
    },
    [currentTopicIndex]
  );

  const transcriptionServiceRef = useRef(null);

  // Handler to initialize transcription service methods
  const handleTranscriptionInit = useCallback((methods) => {
    transcriptionServiceRef.current = methods;
  }, []);

  // Update your navigation functions
  const goToNextTopic = () => {
    setCurrentTopicIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, topics.length - 1);
      currentTopicIndexRef.current = newIndex;

      // Use the transcription service methods
      if (transcriptionServiceRef.current?.isListening()) {
        transcriptionServiceRef.current.restartListening();
      }

      return newIndex;
    });
  };

  const goToPreviousTopic = () => {
    setCurrentTopicIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      currentTopicIndexRef.current = newIndex;

      // Use the transcription service methods
      if (transcriptionServiceRef.current?.isListening()) {
        transcriptionServiceRef.current.restartListening();
      }

      return newIndex;
    });
  };

  const handleSuggestionUpdate = (suggestionId, operation) => {
    setSuggestionsByTopic((prevSuggestionsByTopic) => {
      let topicIndex, suggestionType;

      // Find the suggestion's topic and type (including manual highlights)
      for (const [index, topicSuggestions] of Object.entries(
        prevSuggestionsByTopic
      )) {
        for (const [type, suggestions] of Object.entries(topicSuggestions)) {
          const match = suggestions.find(
            (suggestion) => suggestion.id === suggestionId
          );
          if (match) {
            topicIndex = index;
            suggestionType = type;
            break;
          }
        }
        if (topicIndex) break;
      }

      if (!topicIndex || !suggestionType) return prevSuggestionsByTopic;

      let updatedState;

      if (operation === "complete") {
        updatedState = {
          ...prevSuggestionsByTopic,
          [topicIndex]: {
            ...prevSuggestionsByTopic[topicIndex],
            [suggestionType]: prevSuggestionsByTopic[topicIndex][
              suggestionType
            ].map((suggestion) =>
              suggestion.id === suggestionId
                ? { ...suggestion, done: !suggestion.done }
                : suggestion
            ),
          },
        };
      } else if (operation === "delete") {
        // Find the quoted sentence ID before removing the suggestion
        const quotedSentenceId = prevSuggestionsByTopic[topicIndex][
          suggestionType
        ].find((s) => s.id === suggestionId)?.["Quoted Sentence ID"];

        updatedState = {
          ...prevSuggestionsByTopic,
          [topicIndex]: {
            ...prevSuggestionsByTopic[topicIndex],
            [suggestionType]: prevSuggestionsByTopic[topicIndex][
              suggestionType
            ].filter((suggestion) => suggestion.id !== suggestionId),
          },
        };

        // Update transcription to remove highlight
        setTranscriptionByTopic((prev) => {
          const updatedTranscription = {
            ...prev,
            [topicIndex]: prev[topicIndex].map((entry) =>
              entry.id === quotedSentenceId
                ? {
                  ...entry,
                  generated: false,
                  type: null,
                  isHighlight: false, // Clear highlight flag
                }
                : entry
            ),
          };

          localStorage.setItem(
            "transcriptionByTopic",
            JSON.stringify(updatedTranscription)
          );
          return updatedTranscription;
        });
      }

      // Update localStorage
      localStorage.setItem("suggestionsByTopic", JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const [modalOpened, toggleModal] = useState(false);

  useEffect(() => {
    // console.log("modal state:", modalOpened);
  }, [modalOpened, toggleModal]);

  // Update the resetSuggestions function in App.js
  const resetSuggestions = () => {
    showAlert({
      title: "Clear All Highlights",
      message:
        "This will remove all generated suggestions, their highlights, and any manual highlights you've added to the transcript. This action cannot be undone.",
      onConfirm: () => {
        const defaultState = {
          0: {
            questions: [],
            fallacies: [],
            nuggets: [],
            manual: [],
            notes: [],
          },
        };

        // First, reset transcription
        setTranscriptionByTopic((prevTranscriptionByTopic) => {
          if (!prevTranscriptionByTopic) return { 0: [] };

          const updatedTranscriptionByTopic = Object.keys(
            prevTranscriptionByTopic
          ).reduce((acc, topicIndex) => {
            acc[topicIndex] = (prevTranscriptionByTopic[topicIndex] || []).map(
              (line) => ({
                ...line,
                generated: false,
                isHighlight: false,
                type: null,
              })
            );
            return acc;
          }, {});

          // Update available lines
          if (currentTopicIndex !== undefined && currentTopicIndex !== null) {
            const newAvailableLines = calculateAvailableLines(
              updatedTranscriptionByTopic[currentTopicIndex] || []
            );
            setAvailableLineCount(newAvailableLines);
          }

          // Set localStorage synchronously to ensure proper state
          localStorage.setItem(
            "transcriptionByTopic",
            JSON.stringify(updatedTranscriptionByTopic)
          );
          return updatedTranscriptionByTopic;
        });

        // Then, reset suggestions with proper structure
        setSuggestionsByTopic((prevState) => {
          // Create new state preserving topic structure
          const newState = Object.keys(prevState || {}).reduce(
            (acc, topicIndex) => {
              acc[topicIndex] = {
                questions: [],
                fallacies: [],
                nuggets: [],
                notes: [],
                manual: [],
              };
              return acc;
            },
            {}
          );

          // Ensure at least topic 0 exists
          if (Object.keys(newState).length === 0) {
            newState[0] = defaultState[0];
          }

          // Set localStorage synchronously to ensure proper state
          localStorage.setItem("suggestionsByTopic", JSON.stringify(newState));

          // Force proper initialization by running the initialization function
          const initializedState = initializeSuggestions();
          return initializedState;
        });
      },
      type: "warning",
      confirmText: "Clear All",
      cancelText: "Cancel",
      confirmButtonStyle: "danger",
    });
  };
  const ensureSentenceEndsWithPunctuation = (sentence) => {
    const trimmedSentence = sentence.trim();
    return /[.!?]$/.test(trimmedSentence)
      ? trimmedSentence
      : `${trimmedSentence}.`; // Add period if no punctuation exists
  };

  // Function to restore the cursor with retries
  const restoreCursorPosition = (el, cursorPosition, retries = 10) => {
    if (retries <= 0) return; // Exit after a set number of retries

    requestAnimationFrame(() => {
      const textNode = el.firstChild;
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        const newRange = document.createRange();
        newRange.setStart(textNode, Math.min(cursorPosition, textNode.length));
        newRange.collapse(true);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      } else {
        // Retry if the textNode is not available
        restoreCursorPosition(el, cursorPosition, retries - 1);
      }
    });
  };

  // Function to focus the next line with retries
  const focusNextLine = (currentEl, retries = 10) => {
    if (retries <= 0) return; // Exit after a set number of retries

    requestAnimationFrame(() => {
      const parent = currentEl.parentNode;
      if (parent && parent.nextSibling) {
        const nextLine = parent.nextSibling.querySelector("span");
        if (nextLine) {
          nextLine.focus();
        } else {
          // Retry if nextLine is not available
          focusNextLine(currentEl, retries - 1);
        }
      } else {
        // Retry if parent or nextSibling is not available
        focusNextLine(currentEl, retries - 1);
      }
    });
  };

  // Helper function to calculate available lines
  // const calculateAvailableLines = (transcription) => {
  //   if (!transcription || !Array.isArray(transcription)) return 0;

  //   return transcription.filter(
  //     (entry) =>
  //       entry &&
  //       !entry.generated &&
  //       !entry.done &&
  //       entry.text &&
  //       entry.text.trim().length > 0
  //   ).length;
  // };

  const clearTranscript = () => {
    showAlert({
      title: "Clear Transcript",
      message:
        "You are about to completely delete the transcript. This is permanent and cannot be undone.",
      onConfirm: () => {
        setTranscriptionByTopic((prev) => {
          const updatedTranscription = {
            ...prev,
            [currentTopicIndex]: [],
          };
          setAvailableLineCount(0);
          localStorage.setItem(
            "transcriptionByTopic",
            JSON.stringify(updatedTranscription)
          );
          return updatedTranscription;
        });
      },
      type: "warning",
      confirmText: "Delete",
      cancelText: "Cancel",
      confirmButtonStyle: "danger",
    });
  };

  useEffect(() => {
    checkSetupCompletion();
  }, [isCollapsed, checkSetupCompletion]);

  const [alertModal, setAlertModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: () => { },
    type: "warning",
    confirmText: "",
    cancelText: "",
    confirmButtonStyle: "danger",
  });

  // Add this helper function in App.js
  const showAlert = ({
    title,
    message,
    onConfirm,
    type = "warning",
    confirmText = "Confirm",
    cancelText = "Cancel",
    confirmButtonStyle = "danger",
  }) => {
    setAlertModal({
      isOpen: true,
      title,
      message,
      onConfirm,
      type,
      confirmText,
      cancelText,
      confirmButtonStyle,
    });
  };

  const ensureManualHighlightsPersistence = useCallback(() => {
    setSuggestionsByTopic((prevSuggestions) => {
      // Deep clone the previous state to avoid mutation
      const updatedSuggestions = JSON.parse(JSON.stringify(prevSuggestions));
      let hasChanges = false;

      // Ensure each topic has a manual array and all required arrays
      Object.keys(updatedSuggestions).forEach((topicIndex) => {
        if (!updatedSuggestions[topicIndex]) {
          updatedSuggestions[topicIndex] = {
            questions: [],
            fallacies: [],
            nuggets: [],
            notes: [],
            manual: [],
          };
          hasChanges = true;
        } else if (typeof updatedSuggestions[topicIndex] === "object") {
          // Ensure all required arrays exist
          if (!Array.isArray(updatedSuggestions[topicIndex].manual)) {
            updatedSuggestions[topicIndex].manual = [];
            hasChanges = true;
          }
          if (!Array.isArray(updatedSuggestions[topicIndex].notes)) {
            updatedSuggestions[topicIndex].notes = [];
            hasChanges = true;
          }
          if (!Array.isArray(updatedSuggestions[topicIndex].questions)) {
            updatedSuggestions[topicIndex].questions = [];
            hasChanges = true;
          }
          if (!Array.isArray(updatedSuggestions[topicIndex].fallacies)) {
            updatedSuggestions[topicIndex].fallacies = [];
            hasChanges = true;
          }
          if (!Array.isArray(updatedSuggestions[topicIndex].nuggets)) {
            updatedSuggestions[topicIndex].nuggets = [];
            hasChanges = true;
          }
        }
      });

      if (hasChanges) {
        console.log("Updating suggestions structure:", updatedSuggestions);
        localStorage.setItem(
          "suggestionsByTopic",
          JSON.stringify(updatedSuggestions)
        );
        return updatedSuggestions;
      }
      return prevSuggestions;
    });
  }, []);

  // Then add this useEffect
  useEffect(() => {
    ensureManualHighlightsPersistence();
  }, [ensureManualHighlightsPersistence]);

  useEffect(() => {
    const handleStorageChange = () => {
      // Check if suggestionsByTopic was cleared or modified
      const savedSuggestions = localStorage.getItem("suggestionsByTopic");
      if (!savedSuggestions || savedSuggestions === "null") {
        console.log("Storage cleared, reinitializing state...");
        const initializedState = initializeSuggestions();
        setSuggestionsByTopic(initializedState);
      }
    };

    // Listen for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Also check on focus in case storage was cleared in another tab
    window.addEventListener("focus", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("focus", handleStorageChange);
    };
  }, []);


  const micServiceRef = useRef(null);
  const tabServiceRef = useRef(null);
  const lastProcessedRef = useRef({ text: "", timestamp: 0 });

  const [micActive, setMicActive] = useState(false);
  const [tabActive, setTabActive] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [showEarphonesModal, setShowEarphonesModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const initiateStartProcess = useCallback(() => {
    setShowEarphonesModal(true);
  }, []);

  const stopBothSources = useCallback(() => {
    if (micServiceRef.current) {
      micServiceRef.current.handleStop();
    }
    if (tabServiceRef.current) {
      tabServiceRef.current.handleStop();
    }
  }, []);

  const isAnySourceActive = micActive || tabActive;

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  return (
    <>
      <ThemeProvider>
        <AuthProvider>
          {/* Error Notification */}
          {error && (
            <div className="fixed top-4 mx-4 transform -translate-x-1/2 rounded-lg border border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/20 p-3 shadow-lg animate-fadeIn z-[9999]">
              <div className="flex items-center space-x-2">
                <InformationCircleIcon className="h-5 w-5 text-red-600 dark:text-red-400" />
                <p className="text-sm text-red-700 dark:text-red-400">{error}</p>
              </div>
            </div>
          )}

          {/* Success Notification */}
          {success && (
            <div className="fixed top-4 mx-4 transform -translate-x-1/2 rounded-lg border border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/20 p-3 shadow-lg animate-fadeIn z-[9999]">
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="h-5 w-5 text-green-600 dark:text-green-400" />
                <p className="text-sm text-green-700 dark:text-green-400">{success}</p>
              </div>
            </div>
          )}

          <Routes>
            {/* Main app route - protected by auth */}
            <Route
              path="/"
              element={
                <>
                  {/* <SubscriptionCheck> */}
                  <AlertModal
                    isOpen={alertModal.isOpen}
                    onClose={() => setAlertModal((prev) => ({ ...prev, isOpen: false }))}
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={() => {
                      alertModal.onConfirm();
                      setAlertModal((prev) => ({ ...prev, isOpen: false }));
                    }}
                    type={alertModal.type}
                    confirmText={alertModal.confirmText}
                    cancelText={alertModal.cancelText}
                    confirmButtonStyle={alertModal.confirmButtonStyle}
                  />

                  {/* Main Content */}
                  <div className="fixed inset-0 bg-neutral-50 dark:bg-black overflow-hidden">
                    {/* Transcription Section */}
                    <div className="h-full max-w-3xl mx-auto xl:px-4 xl:pt-4">
                      <TranscriptManager
                        currentTopicIndex={currentTopicIndex}
                        transcriptionByTopic={transcriptionByTopic}
                        setTranscriptionByTopic={setTranscriptionByTopic}
                        suggestionsByTopic={suggestionsByTopic}
                        setSuggestionsByTopic={setSuggestionsByTopic}
                        onSuggestionUpdate={handleSuggestionUpdate}
                        handleCompleteSuggestion={handleCompleteSuggestion}
                        handleDeleteSuggestion={handleDeleteSuggestion}
                        handleTranscript={handleTranscript}
                        setupCompleted={setupCompleted}
                        cooldown={cooldown}
                        hasGeneratedInitially={hasGeneratedInitially}
                        handleTranscriptionInit={handleTranscriptionInit}
                        setActiveTooltip={setActiveTooltip}
                        activeTooltip={activeTooltip}
                        handleGenerateClick={handleGenerateClick}
                        isAnySourceActive={isAnySourceActive}
                        stopBothSources={stopBothSources}
                        initiateStartProcess={initiateStartProcess}
                        isStarting={isStarting}
                        showEarphonesModal={showEarphonesModal}
                        setShowEarphonesModal={setShowEarphonesModal}
                        micServiceRef={micServiceRef}
                        tabServiceRef={tabServiceRef}
                        lastProcessedRef={lastProcessedRef}
                        micActive={micActive}
                        setMicActive={setMicActive}
                        tabActive={tabActive}
                        setTabActive={setTabActive}
                        setIsStarting={setIsStarting}
                        hasUnsavedChanges={hasUnsavedChanges}
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        topics={topics}
                        showPrivacyModal={showPrivacyModal}
                        setShowPrivacyModal={setShowPrivacyModal}
                      />
                    </div>

                    {/* Navbar Component */}
                    <Navbar
                      isCollapsed={isCollapsed}
                      setIsCollapsed={setIsCollapsed}
                      mainTopic={mainTopic}
                      setMainTopic={setMainTopic}
                      contextDocument={contextDocument}
                      handleContextDocumentChange={handleContextDocumentChange}
                      topics={topics}
                      setTopics={setTopics}
                      editTopic={editTopic}
                      setEditTopic={setEditTopic}
                      handleAddTopic={handleAddTopic}
                      handleTopicChange={handleTopicChange}
                      handleRemoveTopic={handleRemoveTopic}
                      handleDragStart={handleDragStart}
                      handleDrop={handleDrop}
                      generateQuestions={generateQuestions}
                      setGenerateQuestions={setGenerateQuestions}
                      generateFallacies={generateFallacies}
                      setGenerateFallacies={setGenerateFallacies}
                      generateNuggets={generateNuggets}
                      setGenerateNuggets={setGenerateNuggets}
                      setupCompleted={setupCompleted}
                      isValidApiKey={isValidApiKey}
                      setIsValidApiKey={handleApiKeyChange}
                      handleGenerateClick={handleGenerateClick}
                      resetSuggestions={resetSuggestions}
                      toggleModal={toggleModal}
                      modalOpened={modalOpened}
                      clearTranscript={clearTranscript}
                      goToPreviousTopic={goToPreviousTopic}
                      goToNextTopic={goToNextTopic}
                      currentTopicIndex={currentTopicIndex}
                      progressPercentage={progressPercentage}
                      error={error}
                      setError={setError}
                      cooldown={cooldown}
                      isAnySourceActive={isAnySourceActive}
                      stopBothSources={stopBothSources}
                      initiateStartProcess={initiateStartProcess}
                      isStarting={isStarting}
                      showEarphonesModal={showEarphonesModal}
                      setShowEarphonesModal={setShowEarphonesModal}
                      micServiceRef={micServiceRef}
                      tabServiceRef={tabServiceRef}
                      lastProcessedRef={lastProcessedRef}
                      generateNotes={generateNotes}
                      setGenerateNotes={setGenerateNotes}
                      remainingGenerations={remainingGenerations}
                      generationsError={generationsError}
                      checkAndIncrementCounter={checkAndIncrementCounter}
                    />
                  </div>
                </>
              }
            />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/cancelled" element={<Navigate to="/" replace />} />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </>
  );

};

// Main Component
const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
