import {
    useEditor,
    EditorContent,
    NodeViewWrapper,
    NodeViewContent,
    ReactNodeViewRenderer,
  } from "@tiptap/react";
  import SuggestionCardView from "./SuggestionCardView";
  import { Node, mergeAttributes } from '@tiptap/core';
  
  const HighlightedContent = Node.create({
    name: 'highlightedContent',
    
    // Define where this node can be used
    group: 'inline',
    inline: true,
    
    // Define what content this node can contain
    content: 'text*', // Changed to text* to ensure proper content handling
    
    // Additional node properties
    selectable: true,
    draggable: false,
  
    addOptions() {
      return {
        HTMLAttributes: {},
      };
    },
  
    addAttributes() {
      return {
        id: { default: null },
        paragraphId: { default: null }, // Add paragraph tracking
        type: { default: 'manual' },
        generated: { default: false },
        isHighlight: { default: false },
        done: { default: false },
        isNew: { default: false },
        isDeleting: { default: false },
        justCompleted: { default: false },
      };
    },
  
    parseHTML() {
      return [{
        tag: 'span[data-highlighted-content]',
        getAttrs: element => ({
          id: element.getAttribute('data-highlight-id'),
          paragraphId: element.getAttribute('data-paragraph-id'),
          type: element.getAttribute('data-highlight-type'),
          generated: element.getAttribute('data-generated') === 'true',
          isHighlight: element.getAttribute('data-is-highlight') === 'true',
          done: element.getAttribute('data-done') === 'true',
          isNew: element.getAttribute('data-is-new') === 'true',
          isDeleting: element.getAttribute('data-is-deleting') === 'true',
          justCompleted: element.getAttribute('data-just-completed') === 'true',
        })
      }];
    },
  
    renderHTML({ node, HTMLAttributes }) {
      const getHighlightClass = () => {
        const styles = {
          question: 'bg-blue-100/70 dark:bg-blue-900/50',
          note: 'bg-green-100/70 dark:bg-green-900/50',
          fallacy: 'bg-rose-100/70 dark:bg-rose-900/50',
          nugget: 'bg-purple-100/70 dark:bg-purple-900/50',
          manual: 'bg-yellow-100/70 dark:bg-yellow-900/50',
        };
  
        if (HTMLAttributes.done) {
          return 'bg-gray-100/70 dark:bg-neutral-800';
        }
  
        return styles[HTMLAttributes.type] || styles.manual;
      };
  
      const classes = [
        getHighlightClass(),
        'hover:opacity-50',
        'p-1',
        'rounded',
        'cursor-text',
        'hover:cursor-pointer',
        'mix-blend-multiply',
        'dark:mix-blend-lighten',
        'relative',
        'group',
        HTMLAttributes.isNew ? 'animate-highlight-appear' : '',
        HTMLAttributes.isDeleting ? 'animate-highlight-delete' : '',
        HTMLAttributes.justCompleted ? 'animate-highlight-complete' : '',
      ].filter(Boolean).join(' ');
  
      return ['span', mergeAttributes(this.options.HTMLAttributes, {
        'data-highlighted-content': '',
        'data-highlight-id': HTMLAttributes.id,
        'data-paragraph-id': HTMLAttributes.paragraphId,
        'data-highlight-type': HTMLAttributes.type,
        'data-generated': HTMLAttributes.generated,
        'data-is-highlight': HTMLAttributes.isHighlight,
        'data-done': HTMLAttributes.done,
        'data-is-new': HTMLAttributes.isNew,
        'data-is-deleting': HTMLAttributes.isDeleting,
        'data-just-completed': HTMLAttributes.justCompleted,
        'class': classes,
      }), 0];
    },
  
    addCommands() {
      return {
        setHighlightedContent: attributes => ({ tr, state, dispatch, chain }) => {
          const { selection } = state;
          if (selection.empty) return false;
  
          // Find the parent paragraph
          let paragraphId = null;
          state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
            if (node.type.name === 'paragraph') {
              paragraphId = node.attrs['data-paragraph-id'];
              return false;
            }
          });
  
          if (!paragraphId) return false;
  
          // Add the paragraphId to attributes
          const newAttributes = { ...attributes, paragraphId };
  
          if (dispatch) {
            chain()
              .command(({ tr, dispatch }) => {
                if (dispatch) {
                  tr.setMeta('addToHistory', false)
                    .setMeta('preventUpdate', true);
                  dispatch(tr);
                }
                return true;
              })
              .wrapInlineNode(this.name, newAttributes)
              .run();
          }
  
          return true;
        },
        unsetHighlightedContent: () => ({ chain }) => {
          return chain()
            .command(({ tr, dispatch }) => {
              if (dispatch) {
                tr.setMeta('addToHistory', false)
                  .setMeta('preventUpdate', true);
                dispatch(tr);
              }
              return true;
            })
            .unwrapInlineNode(this.name)
            .run();
        },
      };
    },
  
    // Add methods to handle node position updates
    addProseMirrorPlugins() {
      return [
        new Plugin({
          appendTransaction: (transactions, oldState, newState) => {
            // Skip if no document changes
            if (!transactions.some(tr => tr.docChanged)) return null;
  
            const tr = newState.tr;
            let modified = false;
  
            // Track highlighted content nodes and ensure they stay with their paragraphs
            newState.doc.descendants((node, pos) => {
              if (node.type.name === this.name) {
                const $pos = newState.doc.resolve(pos);
                let parent = $pos.parent;
                while (parent && parent.type.name !== 'paragraph') {
                  parent = $pos.node($pos.depth - 1);
                }
  
                if (parent && parent.attrs['data-paragraph-id'] !== node.attrs.paragraphId) {
                  // Node is in wrong paragraph, move it to correct one
                  newState.doc.nodesBetween(0, newState.doc.content.size, (searchNode, searchPos) => {
                    if (searchNode.type.name === 'paragraph' && 
                        searchNode.attrs['data-paragraph-id'] === node.attrs.paragraphId) {
                      tr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        paragraphId: searchNode.attrs['data-paragraph-id']
                      });
                      modified = true;
                      return false;
                    }
                  });
                }
              }
            });
  
            if (modified) {
              return tr;
            }
  
            return null;
          }
        })
      ];
    }
  });
  
  const SuggestionCard = Node.create({
    name: "suggestionCard",
    group: "block",
    content: "inline*",
  
    addAttributes() {
      return {
        suggestionId: { default: null },
        paragraphId: { default: null },
        suggestionType: { default: "note" },
        isHighlight: { default: false },
        done: { default: false },
        isDeleting: {
          default: false,
          parseHTML: (element) =>
            element.getAttribute("data-is-deleting") === "true",
          renderHTML: (attributes) => ({
            "data-is-deleting": attributes.isDeleting,
          }),
        },
        justCompleted: {
          default: false,
          parseHTML: (element) =>
            element.getAttribute("data-just-completed") === "true",
          renderHTML: (attributes) => ({
            "data-just-completed": attributes.justCompleted,
          }),
        },
      };
    },
  
    parseHTML() {
      return [{ tag: "div[data-suggestion-card]" }];
    },
  
    renderHTML({ node, HTMLAttributes }) {
      return [
        "div",
        {
          "data-suggestion-card": "",
          "data-paragraph-id": node.attrs.paragraphId,
          ...HTMLAttributes,
        },
        0,
      ];
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(SuggestionCardView);
    },
  });
  
  export { HighlightedContent, SuggestionCard };