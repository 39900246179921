import React from "react";
import {
  XMarkIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const AlertModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  type = "warning",
  confirmButtonStyle = "danger",
}) => {
  if (!isOpen) return null;

  const styles = {
    error: {
      icon: ExclamationCircleIcon,
      iconColor: "text-red-600 dark:text-red-400",
      ring: "ring-red-600/10 dark:ring-red-400/10",
      title: "text-red-900 dark:text-red-300",
    },
    warning: {
      icon: ExclamationTriangleIcon,
      iconColor: "text-amber-600 dark:text-amber-400",
      ring: "ring-amber-600/10 dark:ring-amber-400/10",
      title: "text-amber-900 dark:text-amber-300",
    },
    info: {
      icon: InformationCircleIcon,
      iconColor: "text-blue-600 dark:text-blue-400",
      ring: "ring-blue-600/10 dark:ring-blue-400/10",
      title: "text-blue-900 dark:text-blue-300",
    },
    success: {
      icon: CheckCircleIcon,
      iconColor: "text-emerald-600 dark:text-emerald-400",
      ring: "ring-emerald-600/10 dark:ring-emerald-400/10",
      title: "text-emerald-900 dark:text-emerald-300",
    },
  };

  const buttonStyles = {
    danger: "bg-red-600 hover:bg-red-700 focus:ring-red-600",
    primary: "bg-blue-600 hover:bg-blue-700 focus:ring-blue-600",
    success: "bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-600",
  };

  const currentStyle = styles[type] || styles.info;
  const Icon = currentStyle.icon;

  const formatMessage = (message) => {
    return message.split("\n").map((line, index) => {
      if (line.trim() === "") return <br key={index} />;

      if (line.trim().startsWith("•") || line.trim().startsWith("- ")) {
        return (
          <li key={index} className="ml-4 mb-2 text-gray-600 dark:text-gray-300">
            {line.replace(/^[•-]\s*/, "")}
          </li>
        );
      }

      if (line.trim().endsWith(":")) {
        return (
          <h4 key={index} className="font-medium text-gray-900 dark:text-white mt-4 mb-2">
            {line}
          </h4>
        );
      }

      return (
        <p key={index} className="mb-2 text-gray-600 dark:text-gray-300">
          {line}
        </p>
      );
    });
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex min-h-full items-center justify-center p-4">
        {/* Backdrop */}
        <div
          className="fixed inset-0 bg-gray-950/30 dark:bg-gray-950/50 backdrop-blur-sm transition-opacity"
          onClick={onClose}
          aria-hidden="true"
        />

        {/* Modal */}
        <div className="relative transform overflow-hidden rounded-2xl bg-white dark:bg-gray-900 text-left shadow-2xl transition-all w-full max-w-lg">
          <div className="px-6 pt-6 pb-4">
            <div className="flex items-start gap-4">
              <div className={`rounded-xl p-2 ${currentStyle.ring} flex-shrink-0`}>
                <Icon className={`h-6 w-6 ${currentStyle.iconColor}`} />
              </div>
              
              <div className="flex-1 min-w-0">
                {title && (
                  <h3 
                    id="modal-title"
                    className={`text-lg font-semibold mb-3 ${currentStyle.title}`}
                  >
                    {title}
                  </h3>
                )}
                <div className="text-sm space-y-1">
                  {formatMessage(message)}
                </div>
              </div>

              <button
                onClick={onClose}
                className="rounded-lg p-1.5 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                aria-label="Close modal"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="px-6 py-4 bg-gray-50 dark:bg-gray-800/50 flex flex-row-reverse gap-3">
            <button
              onClick={onConfirm}
              className={`
                inline-flex justify-center rounded-lg px-4 py-2.5
                text-sm font-medium text-white
                focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900
                transition-colors
                ${buttonStyles[confirmButtonStyle] || buttonStyles.primary}
              `}
            >
              {confirmText}
            </button>
            <button
              onClick={onClose}
              className="inline-flex justify-center rounded-lg px-4 py-2.5
                       text-sm font-medium
                       border border-gray-300 dark:border-gray-600
                       text-gray-700 dark:text-gray-300
                       hover:bg-gray-50 dark:hover:bg-gray-800
                       focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900
                       transition-colors"
            >
              {cancelText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;