class AudioCaptureService {
    constructor(onAudioStream, onError, audioSource = 'tab') {
      this.onAudioStream = onAudioStream;
      this.onError = onError;
      this.audioSource = audioSource;
      this.stream = null;
      this.isCapturing = false;
      this.debugMode = true;
    }

    async startCapture() {
      try {
        if (this.stream) {
          this.stopCapture();
        }

        if (this.audioSource === 'microphone') {
          await this.startMicrophoneCapture();
        } else {
          await this.startTabCapture();
        }

      } catch (error) {
        this.log("Capture error:", error);
        this.onError(error);
        if (this.stream) {
          this.stopCapture();
        }
        throw error;
      }
    }

    async startMicrophoneCapture() {
      this.log("Requesting microphone access...");
      
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false,
          channelCount: 1,
          sampleRate: 16000
        }
      });

      const audioTrack = micStream.getAudioTracks()[0];
      if (!audioTrack) {
        throw new Error("No microphone track found.");
      }

      // Enable the audio track
      audioTrack.enabled = true;

      // Log track details for debugging
      this.log("Microphone track details:", {
        settings: audioTrack.getSettings(),
        constraints: audioTrack.getConstraints(),
        capabilities: audioTrack.getCapabilities(),
        enabled: audioTrack.enabled,
        muted: audioTrack.muted,
        readyState: audioTrack.readyState,
        id: audioTrack.id,
        kind: audioTrack.kind,
        label: audioTrack.label
      });

      this.stream = micStream;
      this.isCapturing = true;
      this.onAudioStream(micStream);
      this.log("Microphone capture started successfully");
    }

    async startTabCapture() {
      this.log("Requesting tab capture...");
      
      // We need to keep the video options for the permissions dialog to work
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        audio: {
          // Audio constraints
          echoCancellation: false,
          noiseSuppression: false,
          autoGainControl: false,
          channelCount: 1,
          sampleRate: 16000
        },
        video: {
          // Minimal video track for permissions
          displaySurface: "browser",
          height: 1,
          width: 1,
          frameRate: 1
        }
      });

      // Immediately stop video tracks as we don't need them
      displayStream.getVideoTracks().forEach(track => {
        track.stop();
        this.log("Stopped video track");
      });

      const audioTrack = displayStream.getAudioTracks()[0];
      if (!audioTrack) {
        throw new Error("No tab audio track found. Did you select 'Share tab audio'?");
      }

      // Enable the audio track
      audioTrack.enabled = true;

      // Log track details for debugging
      this.log("Tab audio track details:", {
        settings: audioTrack.getSettings(),
        constraints: audioTrack.getConstraints(),
        capabilities: audioTrack.getCapabilities(),
        enabled: audioTrack.enabled,
        muted: audioTrack.muted,
        readyState: audioTrack.readyState,
        id: audioTrack.id,
        kind: audioTrack.kind,
        label: audioTrack.label
      });
      
      const audioOnlyStream = new MediaStream([audioTrack]);
      this.stream = audioOnlyStream;
      
      this.isCapturing = true;
      this.onAudioStream(audioOnlyStream);
      this.log("Tab audio capture started successfully");
    }

    async stopCapture() {
      this.log("Stopping capture...");
      
      if (this.stream) {
        const tracks = this.stream.getTracks();
        
        try {
          // Stop all tracks and wait for them to end
          await Promise.all(tracks.map(track => {
            return new Promise((resolve, reject) => {
              this.log("Track before stop:", {
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState,
                label: track.label
              });
    
              // Set up event listeners before stopping
              track.onended = () => {
                this.log("Track ended:", {
                  enabled: track.enabled,
                  muted: track.muted,
                  readyState: track.readyState,
                  label: track.label
                });
                resolve();
              };
    
              track.onerror = (error) => {
                this.log("Track error:", error);
                reject(error);
              };
    
              // Stop the track
              track.stop();
              
              this.log("Track after stop:", {
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState,
                label: track.label
              });
    
              // Fallback timeout in case onended doesn't fire
              setTimeout(() => {
                this.log("Track stop timeout reached:", {
                  enabled: track.enabled,
                  muted: track.muted,
                  readyState: track.readyState,
                  label: track.label
                });
                resolve();
              }, 500); // 500ms timeout
            });
          }));
    
          this.log("All tracks stopped successfully");
        } catch (error) {
          this.log("Error stopping tracks:", error);
          throw error;
        } finally {
          // Clean up stream reference and state
          this.stream = null;
          this.isCapturing = false;
          this.log("Capture cleanup completed");
        }
      } else {
        this.log("No stream to stop");
        this.isCapturing = false;
      }
    
      // Return a promise that resolves after a small additional delay
      // to ensure all resources are properly released
      return new Promise(resolve => {
        setTimeout(() => {
          this.log("Final capture cleanup completed");
          resolve();
        }, 100);
      });
    }

    log(message, ...args) {
      if (this.debugMode) {
        console.log(`[AudioCapture] ${message}`, ...args);
      }
    }
}

export default AudioCaptureService;