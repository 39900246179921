import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChange, getCurrentUser } from './authService';
import { checkSubscriptionStatus } from './stripeService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const checkAndUpdateSubscription = async (currentUser) => {
    if (currentUser) {
      const status = await checkSubscriptionStatus();
      setIsSubscribed(status);
    } else {
      setIsSubscribed(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(async (user) => {
      setUser(user);
      await checkAndUpdateSubscription(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    isSubscribed,
    loading,
    refreshSubscription: () => checkAndUpdateSubscription(user)
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};