import { ArrowsRightLeftIcon, BookmarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useRef } from "react";

const EditorTooltip = ({
  isVisible,
  position,
  onSpeakerSwap,
  onAddHighlight,
  setActiveTooltip,
  activeTooltip
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);
  const lastClickPos = useRef({ x: 0, y: 0 });
  const [showSpeakerSwap, setShowSpeakerSwap] = useState(false);

  useEffect(() => {
    const handleClick = (e) => {
      lastClickPos.current = { x: e.clientX, y: e.clientY };
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const container = document.querySelector('.transcription-container');
    const paragraphs = container?.querySelectorAll('.ProseMirror > p');
    const activeParagraph = paragraphs?.[activeTooltip];
    
    if (!container || !activeParagraph) return;

    // Check if paragraph contains speaker labels
    const hasSpeakerLabel = /\[(Microphone|Tab Audio|MICROPHONE|TAB AUDIO)\]/.test(activeParagraph.textContent);
    setShowSpeakerSwap(hasSpeakerLabel);

    const updatePosition = () => {
      const containerRect = container.getBoundingClientRect();
      const paragraphRect = activeParagraph.getBoundingClientRect();
      const tooltipRect = tooltipRef.current?.getBoundingClientRect();
      
      if (!tooltipRect) return;

      // Create a temporary span to measure the text width
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'pre';
      span.style.font = window.getComputedStyle(activeParagraph).font;
      span.textContent = activeParagraph.textContent;
      document.body.appendChild(span);

      const textWidth = span.getBoundingClientRect().width;
      document.body.removeChild(span);

      // Calculate horizontal position
      const textLeft = paragraphRect.left + textWidth;
      let left = textLeft - containerRect.left + 8;
      
      const wouldOverflow = (textLeft + tooltipRect.width + 8) > containerRect.right;
      if (wouldOverflow) {
        left = paragraphRect.right - containerRect.left - tooltipRect.width - 8;
      }

      // Calculate vertical position based on click position
      const clickY = lastClickPos.current.y;
      const relativeClickY = clickY - containerRect.top + container.scrollTop + 20;
      
      // Ensure tooltip stays within paragraph bounds
      const minTop = paragraphRect.top - containerRect.top;
      const maxTop = minTop + paragraphRect.height;
      const top = Math.max(minTop, Math.min(maxTop, relativeClickY));

      setTooltipPosition({
        top,
        left: Math.max(8, Math.min(left, containerRect.width - tooltipRect.width - 8))
      });
    };

    updatePosition();
    container.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    
    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(container);

    return () => {
      container.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
      resizeObserver.disconnect();
    };
  }, [isVisible, position, activeTooltip]);

  if (!isVisible) return null;

  return (
    <div
      ref={tooltipRef}
      data-tooltip
      className="absolute z-[10] bg-white dark:bg-neutral-900 shadow-sm rounded-lg border border-neutral-300 dark:border-neutral-600 flex flex-row items-center"
      style={{
        top: `${tooltipPosition.top}px`,
        left: `${tooltipPosition.left}px`,
        transform: 'translateY(-50%)',
      }}
    >
      {showSpeakerSwap && (
        <>
          <button
            onClick={onSpeakerSwap}
            className="flex items-center gap-1 px-2 py-1.5 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-neutral-600 rounded-l-md transition-colors"
            title="Switch Speaker"
          >
            <ArrowsRightLeftIcon className="h-4 w-4" />
            <span className="hidden sm:inline">Speaker</span>
          </button>
          <div className="w-px h-5 bg-gray-200 dark:bg-neutral-700" />
        </>
      )}
      <button
        onClick={onAddHighlight}
        className={`flex items-center gap-1 px-2 py-1.5 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-neutral-600 transition-colors ${showSpeakerSwap ? 'rounded-r-md' : 'rounded-md'}`}
        title="Add Highlight"
      >
        <BookmarkIcon className="h-4 w-4" />
        <span className="hidden sm:inline">Highlight</span>
      </button>
    </div>
  );
};

export default EditorTooltip;