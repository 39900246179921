import React from "react";
import {
  CheckCircleIcon,
  TrashIcon,
  QuestionMarkCircleIcon,
  ExclamationTriangleIcon,
  LightBulbIcon,
  EyeIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
// Redesigned SuggestionCard component
const SuggestionCardView = ({ node, editor }) => {
  const type = node.attrs.suggestionType;
  const isHighlight = node.attrs.isHighlight;

  const handleComplete = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    e.preventDefault(); // Prevent any default behavior
    if (typeof editor.options.onCompleteSuggestion === "function") {
      editor.options.onCompleteSuggestion(node.attrs.suggestionId);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    e.preventDefault(); // Prevent any default behavior
    if (typeof editor.options.onDeleteSuggestion === "function") {
      editor.options.onDeleteSuggestion(node.attrs.suggestionId);
    }
  };

  // Get card style based on type
  const getCardStyle = () => {
    if (node.attrs.done) {
      return {
        container:
          "bg-gray-50 dark:bg-neutral-800 border-neutral-300 dark:border-neutral-600",
        icon: "text-gray-300 dark:text-white",
        text: "text-gray-400 dark:text-white",
      };
    }

    const styles = {
      manual: {
        container:
          "bg-yellow-50/80 dark:bg-transparent border-yellow-200 dark:border-yellow-500",
        icon: "text-yellow-500 dark:text-yellow-500",
        text: "text-yellow-700 dark:text-neutral-200",
      },
      question: {
        container:
          "bg-blue-50/80 dark:bg-transparent border-blue-200 dark:border-blue-500",
        icon: "text-blue-500 dark:text-blue-500",
        text: "text-blue-700 dark:text-neutral-200",
      },
      fallacy: {
        container:
          "bg-rose-50/80 dark:bg-transparent border-rose-200 dark:border-rose-500",
        icon: "text-rose-500 dark:text-rose-500",
        text: "text-rose-700 dark:text-neutral-200",
      },
      nugget: {
        container:
          "bg-purple-50/80 dark:bg-transparent border-purple-200 dark:border-purple-500",
        icon: "text-purple-500 dark:text-purple-500",
        text: "text-purple-700 dark:text-neutral-200",
      },
      note: {
        container: "bg-green-50/80 dark:bg-transparent border-green-200 dark:border-green-500",
        icon: "text-green-500 dark:text-green-500",
        text: "text-green-700 dark:text-neutral-200",
      },
    };

    return styles[type] || styles.question;
  };

  const style = getCardStyle();

  const getIcon = () => {
    if (isHighlight) return BookmarkIcon;
    switch (type) {
      case "question":
        return QuestionMarkCircleIcon;
      case "fallacy":
        return ExclamationTriangleIcon;
      case "nugget":
        return LightBulbIcon;
      case "note":
        return BookmarkIcon;
      default:
        return BookmarkIcon;
    }
  };

  const Icon = getIcon();

  const getTitle = () => {
    if (isHighlight) return "Highlight";
    switch (type) {
      case "question":
        return "Follow-up Question";
      case "fallacy":
        return "Potential Logic Issue";
      case "nugget":
        return "Key Insight";
      case "note":
        return "Note";
      default:
        return "Highlight";
    }
  };

  return (
    <NodeViewWrapper>
      <div
        contentEditable={false}
        className={`group relative border rounded-lg shadow-sm transition-all duration-200 text-black ${
          style.container
        } 
            ${node.attrs.done ? "opacity-60" : "hover:shadow-md"}`}
      >
        {/* Card Header */}
        <div className="flex items-center justify-between p-3 dark:border-neutral-700 border-b border-opacity-20 dark:border-opacity-90">
          <div className="flex items-center space-x-2">
            <Icon className={`h-5 w-5 ${style.icon}`} />
            <span className={`text-sm font-medium ${style.text}`}>
              {getTitle()}
            </span>
          </div>

          {/* Action Buttons - Show on hover */}
          <div className="flex items-center space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={handleComplete}
              tabIndex="-1"
              className={`p-1.5 rounded-md transition-colors ${
                node.attrs.done
                  ? "text-green-500 bg-green-50 hover:bg-green-100 dark:bg-neutral-800 dark:hover:bg-neutral-700"
                  : "text-gray-400 dark:text-white bg-white hover:bg-gray-50 hover:text-gray-600 dark:bg-neutral-800 dark:hover:bg-neutral-700 hover:text-blue-300"
              }`}
              title={
                node.attrs.done ? "Mark as Incomplete" : "Mark as Complete"
              }
            >
              {node.attrs.done ? (
                <CheckCircleIcon className="h-4 w-4" />
              ) : (
                <EyeIcon className="h-4 w-4" />
              )}
            </button>

            <button
              onClick={handleDelete}
              className="p-1.5 rounded-md text-gray-400 dark:text-white bg-white dark:bg-neutral-800 dark:hover:bg-neutral-700 hover:text-red-500 transition-colors"
              title="Remove Suggestion"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Card Content - Show for all cards */}
        <div
          className={`p-3 text-sm
                ${
                  node.attrs.done
                    ? "text-neutral-800 dark:text-neutral-200 opacity-30"
                    : "text-black dark:text-neutral-300"
                }`}
        >
          <NodeViewContent />
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default SuggestionCardView;
