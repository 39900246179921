import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from './authContext';
import { LoginModal } from './LoginModal';
import { InformationCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { signOutUser } from './authService';
import DarkModeToggle from './DarkModeToggle';

const LoginButton = ({
    className,
    generateNotes,
    setGenerateNotes,
    showPrivacyModal,
    setShowPrivacyModal
}) => {
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [currentTheme, setCurrentTheme] = useState(localStorage.theme || 'system');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleLogout = async () => {
        await signOutUser();
        setShowDropdown(false);
    };

    const getThemeText = () => {
        switch (currentTheme) {
            case 'light':
                return 'Light mode';
            case 'dark':
                return 'Dark mode';
            default:
                return 'Same as system';
        }
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setShowDropdown(!showDropdown)}
                className={className || "flex items-center justify-center rounded-full"}
            >
                {user?.photoURL ? (
                    <img src={user.photoURL} alt="" className="w-6 h-6 rounded-full" />
                ) : (
                    <UserIcon className="h-5 w-5 text-gray-600 dark:text-neutral-300" />
                )}
            </button>

            {/* Dropdown Menu */}
            {showDropdown && (
                <div className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700">
                    <div className="py-1">
                        {user ? (
                            <>
                                <div className="px-4 py-2 border-b border-neutral-200 dark:border-neutral-700">
                                    <p className="text-sm font-medium text-gray-900 dark:text-white">{user.displayName}</p>
                                    <p className="text-sm text-gray-500 dark:text-neutral-400 truncate">{user.email}</p>
                                </div>
                                {/* Add Dark Mode Toggle */}
                                <div className="px-4 py-2 flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700">
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-700 dark:text-neutral-200">Theme</span>
                                        <span className="text-xs text-gray-500 dark:text-neutral-400">{getThemeText()}</span>
                                    </div>
                                    <DarkModeToggle onThemeChange={setCurrentTheme} />
                                </div>
                                <button
                                    onClick={handleLogout}
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-700"
                                >
                                    Sign out
                                </button>
                                {/* Moved Privacy Button */}
                                <div className="border-t border-neutral-200 dark:border-neutral-700">
                                    <button
                                        className="w-full flex items-center px-4 py-2 text-sm text-gray-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-700"
                                        onClick={setShowPrivacyModal}
                                        title="Privacy Information"
                                    >
                                        <InformationCircleIcon className="h-4 w-4 mr-2" />
                                        <span>About Respaced</span>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* Add Dark Mode Toggle for non-logged in users */}
                                <div className="px-4 py-2 flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700">
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-700 dark:text-neutral-200">Theme</span>
                                        <span className="text-xs text-gray-500 dark:text-neutral-400">{getThemeText()}</span>
                                    </div>
                                    <DarkModeToggle />
                                </div>
                                <button
                                    onClick={() => {
                                        setShowModal(true);
                                        setShowDropdown(false);
                                    }}
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-700"
                                >
                                    Sign in
                                </button>
                                {/* Privacy Button for non-logged in users */}
                                <div className="border-t border-neutral-200 dark:border-neutral-700">
                                    <button
                                        className="w-full flex items-center px-4 py-2 text-sm text-gray-700 dark:text-neutral-200 hover:bg-gray-100 dark:hover:bg-neutral-700"
                                        onClick={setShowPrivacyModal}
                                        title="Privacy Information"
                                    >
                                        <InformationCircleIcon className="h-4 w-4 mr-2" />
                                        <span>About Respaced</span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            <LoginModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
};

export default LoginButton;