import { useCallback, useEffect, useRef } from "react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from '@tiptap/extension-placeholder'

const useEditorContentManager = ({
  currentTopicIndex,
  transcriptionByTopic,
  suggestionsByTopic,
  prevSuggestions,
  setPrevSuggestions,
  setHasUnsavedChanges,
  handleCompleteSuggestion,
  handleDeleteSuggestion,
  Highlight,
  SuggestionCard,
  getPlainText
}) => {
  const lastSavedContent = useRef("");
  const updateTimeoutRef = useRef(null);
  const isInitialMount = useRef(true);
  const lastTopicStateRef = useRef({
    transcription: transcriptionByTopic[currentTopicIndex],
    suggestions: suggestionsByTopic[currentTopicIndex],
  });

  // Helper function to normalize content for comparison
  const normalizeContent = useCallback((content) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    const paragraphs = Array.from(div.querySelectorAll("p"));

    return paragraphs
      .map((p) => {
        const text = p.textContent.replace(/\s+/g, " ").trim();
        return text.length === 0 ? "[EMPTY_PARAGRAPH]" : text;
      })
      .join("\n");
  }, []);

  // Create valid content structure
  const createValidContent = useCallback(
    (sentence, suggestion, prevSuggestion, existingHighlight) => {
      const content = [];
      const isNewSuggestion = suggestion && !prevSuggestion;
      const isDeleting = !suggestion && prevSuggestion;
      const justCompleted =
        suggestion?.done && prevSuggestion && !prevSuggestion.done;

      const paragraphContent = {
        type: "paragraph",
        attrs: {
          "data-paragraph-id": sentence.id,
          class: "tracked-paragraph hover:bg-gray-100 dark:hover:bg-neutral-600 rounded",
        },
        content: [],
      };

      if (existingHighlight) {
        paragraphContent.content.push({
          type: "text",
          marks: [
            {
              type: "highlight",
              attrs: existingHighlight.attrs,
            },
          ],
          text: sentence.text,
        });
      } else if (suggestion || prevSuggestion) {
        paragraphContent.content.push({
          type: "text",
          marks: [
            {
              type: "highlight",
              attrs: {
                id: sentence.id,
                paragraphId: sentence.id,
                generated:
                  suggestion?.type !== "manual" &&
                  (!!suggestion || !!prevSuggestion),
                type: suggestion?.type || prevSuggestion?.type || "manual",
                isHighlight:
                  suggestion?.type === "manual" ||
                  prevSuggestion?.type === "manual",
                isOpen: false,
                done: suggestion?.done || false,
                isNew: isNewSuggestion && suggestion?.type !== "manual",
                isDeleting,
                justCompleted,
              },
            },
          ],
          text: sentence.text,
        });
      } else {
        paragraphContent.content.push({
          type: "text",
          text: sentence.text,
        });
      }

      content.push(paragraphContent);

      if (suggestion) {
        const insightText =
          typeof suggestion.Insight === "string"
            ? suggestion.Insight
            : JSON.stringify(suggestion.Insight);

        if (insightText.trim()) {
          content.push({
            type: "suggestionCard",
            attrs: {
              suggestionId: suggestion.id,
              paragraphId: sentence.id,
              suggestionType: suggestion.type,
              isHighlight: suggestion.type === "manual",
              done: suggestion.done,
            },
            content: [{ type: "text", text: insightText }],
          });
        }
      }

      return content;
    },
    []
  );

  // Function to check for content changes
  const checkForChanges = useCallback(
    (currentContent, savedContent) => {
      // Don't trigger changes on initial mount
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return false;
      }

      if (!currentContent || !savedContent) return false;

      const normalizedCurrent = normalizeContent(currentContent);
      const normalizedLast = normalizeContent(savedContent);

      // Add debug logging
      console.log('Checking changes:', {
        current: normalizedCurrent,
        last: normalizedLast,
        hasChanges: normalizedCurrent !== normalizedLast
      });

      return normalizedCurrent !== normalizedLast;
    },
    [normalizeContent]
  );

  // Define onUpdate as a callback
  const onUpdate = useCallback(({ editor }) => {
    if (!editor) return;

    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }

    updateTimeoutRef.current = setTimeout(() => {
      const currentContent = editor.getHTML();
      const hasChanges = checkForChanges(
        currentContent,
        lastSavedContent.current
      );
      console.log('Update detected:', {
        currentContent,
        lastSaved: lastSavedContent.current,
        hasChanges
      });
      setHasUnsavedChanges(hasChanges);
    }, 300);
  }, [checkForChanges, setHasUnsavedChanges]);


  // Initialize editor with the onUpdate handler
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: {
          keepMarks: true,
          HTMLAttributes: {
            class: "tracked-paragraph hover:bg-gray-100 dark:hover:bg-neutral-600 rounded",
            contenteditable: "true"
          },
        },
      }),
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: 'highlight-mark',
        },
      }),
      Placeholder.configure({
        placeholder: 'Start transcribing or copy-paste your transcript here.',
        emptyEditorClass: 'is-editor-empty',
      }),
      SuggestionCard,
    ],
    content: "<p></p>",
    editable: true,
    onCompleteSuggestion: handleCompleteSuggestion,
    onDeleteSuggestion: handleDeleteSuggestion,
    onTransaction: ({ transaction, editor }) => {
      if (!transaction.docChanged) return;

      const paragraphHighlights = new Map();

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "paragraph") {
          const paragraphId = node.attrs["data-paragraph-id"];
          if (!paragraphId) return;

          let highlightMark = null;
          node.content.descendants((textNode) => {
            if (textNode.marks) {
              const mark = textNode.marks.find(
                (m) => m.type.name === "highlight"
              );
              if (mark && mark.attrs.paragraphId === paragraphId) {
                highlightMark = mark;
              }
            }
          });

          if (highlightMark) {
            paragraphHighlights.set(paragraphId, highlightMark);
          }
        }
      });

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "paragraph") {
          const paragraphId = node.attrs["data-paragraph-id"];
          if (!paragraphId) return;

          const expectedHighlight = paragraphHighlights.get(paragraphId);
          if (expectedHighlight) {
            let hasCorrectHighlight = false;
            node.content.descendants((textNode) => {
              if (textNode.marks) {
                const mark = textNode.marks.find(
                  (m) =>
                    m.type.name === "highlight" &&
                    m.attrs.paragraphId === paragraphId
                );
                if (mark) hasCorrectHighlight = true;
              }
            });

            if (!hasCorrectHighlight) {
              const tr = editor.state.tr;
              const from = pos + 1;
              const to = pos + node.nodeSize - 1;
              tr.addMark(from, to, expectedHighlight);
              editor.view.dispatch(tr);
            }
          }
        }
      });
    },
    onUpdate, // Use the defined onUpdate handler
    editorProps: {
      attributes: {
        class: "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none h-full dark:text-white",
      },
      handleDOMEvents: {
        touchstart: (view, event) => {
          // Store the current viewport and scroll position
          const container = view.dom.closest('.transcription-container');
          if (container) {
            container.dataset.viewportHeight = window.visualViewport.height;
            container.dataset.scrollY = container.scrollTop;
          }
          return false;
        },
        touchend: (view, event) => {
          const container = view.dom.closest('.transcription-container');
          if (!container) return false;

          // Check if keyboard was just dismissed (viewport height increased)
          const previousHeight = parseFloat(container.dataset.viewportHeight);
          const currentHeight = window.visualViewport.height;

          if (previousHeight && currentHeight > previousHeight) {
            // Keyboard was just dismissed - restore scroll position
            const savedScroll = parseInt(container.dataset.scrollY || '0', 10);
            requestAnimationFrame(() => {
              container.scrollTop = savedScroll;
            });
          }
          return false;
        }
      }
    },
    // onCreate: ({ editor }) => {
    //   // Disable native iOS text input handling
    //   editor.view.dom.setAttribute('inputmode', 'none');
    // }
  });

  // Add this effect to initialize lastSavedContent when the editor is created
  useEffect(() => {
    if (editor) {
      lastSavedContent.current = editor.getHTML();
    }
  }, [editor]);

  useEffect(() => {
    if (!editor) return;

    const currentContent = editor.getHTML();
    const currentPlainText = getPlainText(currentContent);
    const newPlainText = (transcriptionByTopic[currentTopicIndex] || [])
      .map((sentence) => sentence.text)
      .join(" ");

    if (currentPlainText !== newPlainText) {
      const existingHighlights = new Map();
      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === "text" && node.marks) {
          const mark = node.marks.find((m) => m.type.name === "highlight");
          if (mark) {
            existingHighlights.set(mark.attrs.id, mark);
          }
        }
      });

      let content = [];
      const sentences = transcriptionByTopic[currentTopicIndex] || [];
      const currentSuggestions = suggestionsByTopic[currentTopicIndex] || {};
      const previousSuggestions = prevSuggestions[currentTopicIndex] || {};

      if (sentences.length === 0) {
        content = [{ type: "paragraph" }];
      } else {
        sentences.forEach((sentence) => {
          const existingHighlight = existingHighlights.get(sentence.id);

          const suggestion = Object.values(currentSuggestions)
            .flat()
            .find((s) => s["Quoted Sentence ID"] === sentence.id);

          const prevSuggestion = Object.values(previousSuggestions)
            .flat()
            .find((s) => s["Quoted Sentence ID"] === sentence.id);

          const validContent = createValidContent(
            sentence,
            suggestion,
            prevSuggestion,
            existingHighlight
          );
          content = [...content, ...validContent];
        });
      }

      const tr = editor.state.tr
        .setMeta("isCardUpdate", true)
        .setMeta("preserveMarks", true)
        .replaceWith(
          0,
          editor.state.doc.content.size,
          editor.schema.nodeFromJSON({
            type: "doc",
            content: content,
          })
        );

      editor.view.dispatch(tr);
      lastSavedContent.current = editor.getHTML();

      setPrevSuggestions((prev) => ({
        ...prev,
        [currentTopicIndex]: currentSuggestions,
      }));

      const { from, to } = editor.state.selection;
      requestAnimationFrame(() => {
        try {
          editor.commands.setTextSelection({
            from: Math.min(from, editor.state.doc.content.size),
            to: Math.min(to, editor.state.doc.content.size),
          });
        } catch (error) {
          console.warn("Could not restore cursor position:", error);
        }
      });
    }
  }, [
    editor,
    currentTopicIndex,
    transcriptionByTopic,
    suggestionsByTopic,
    createValidContent,
  ]);

  return {
    editor,
    lastSavedContent,
    updateTimeoutRef,
    createValidContent,
    checkForChanges,
    normalizeContent,
    onUpdate
  };
};

export default useEditorContentManager;
